.footerSocial {
  display: flex;
  margin-top: 30px;
  li {
    transition: all 0.4s;
    width: 40px;
    height: 40px;
    background: #3934a3;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    cursor: pointer;
    &:hover {
      background: rgb(255, 112, 82);
      transition: all 1s;
    }
  }
}

.footerTGU {
  width: 175px;
  height: 71px;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;