.courseEdit {
  font-family: Manrope;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  .editPopupTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #2e2e3d;
    text-align: start;
  }
  .popupTheme {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #7f8191;
    margin: 0;
  }
  .value {
    color: #2e2e3d;
  }
  .deleteTheme {
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #ff5e5e;
    display: flex;
    align-items: center;
    svg {
      margin-left: 12px;
    }
  }
  .students {
    background: #ffffff;
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 12px 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 10px;
    h3 {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #a4a6b3;
    }
    ul {
      margin: 0;
      display: flex;
      flex-wrap: wrap;
    }
  }
  .studentItem {
    background: #f5f6fa;
    border-radius: 10px;
    padding: 5px 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    display: flex;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;
    svg {
      transition: all 0.4s;
      margin-left: 9px;
      &:hover {
        transform: scale(1.05);
        transition: all 0.4s;
      }
    }
  }
  button {
    padding: 18px 24px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    background: #7671dd;
    box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
      0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
      0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
      0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
    border-radius: 15px;
    transition: all 0.4s;
    cursor: pointer;
    margin-right: 10px;
    &:hover {
      transform: scale(1.05);
      transition: all 0.4s;
    }
  }
  .close {
    background: white;
    color: black;
    border: 2px solid #F0F0F0;
    box-shadow: none;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;