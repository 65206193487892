.smallDiviceWrap {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  background: #f5f6fa;
  justify-content: center;
    padding: 15%;
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 1.3em;
      color: #2e2e3d;
      margin: 5% 0 0 0;
    }
    img {
      margin-top: 5%;
      width: 80%;
    }
    .smallDevice__redirect {
      color: #7671dd;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: bold;
      font-size: 1em;
      svg {
        margin-left: 5px;
        transition: transform 0.4s ease;
      }
      &:hover {
        svg {
          transform: scale(1.05);
          transition: transform 0.4s ease;
        }
      }
    }
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;