.Button {
    cursor: pointer;
    color:white;
    padding: 18px 24px;
    display: flex;
    align-items: center;
}

.withAddon {
    padding: 0 16px;
}

.addonLeft, .addonRight {
    display: flex;
}

.addonRight svg, .addonLeft svg {
    color: var(--hint-redesigned);
}


.clear {
    padding: 0;
    border: none;
    background: none;
    outline: none;
}

.filled {
    border: none;
    outline: none;
    border-radius: 15px;
}

.outline {
    background: none;
    border-radius: 34px;

    &:hover {
        border: 2px solid var(--accent-redesigned);
    }

    &.normal {
        border: 2px solid var(--icon-redesigned);
    }

    &.error {
        border: 2px solid var(--cancel-redesigned);
    }

    &.success {
        border: 2px solid var(--save-redesigned);
    }
}

.square {
    padding: 0;
}

.square.m {
    width: var(--font-line-m);
    height: var(--font-line-m);
}

.square.l {
    width: var(--font-line-l);
    height: var(--font-line-l);
}

.square.xl {
    width: var(--font-line-xl);
    height: var(--font-line-xl);
}

.m {
    font-size: 16px;
}

.l {
    font-size: 18px;
}

.xl {
    font-size: 24px;
}

.disabled {
    opacity: 0.5;
}

.fullWidth {
    width: 100%;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;