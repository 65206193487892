.chipsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  display: flex;
  background: #f5f6fa;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  color: #2e2e3d;
  gap: 10px;
  padding: .5rem;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;