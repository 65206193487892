.specTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #2E2E3D;
  padding: 20px 0 10px 0;
  margin: 0;
  &:first-of-type {
    padding: 0px 0 10px 0;
  }
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;