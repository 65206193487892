.selectWrapper {
  background: #fff !important;
  border: 2px solid #f5f6fa !important;
  border-radius: 1rem !important;
  box-sizing: border-box;
  cursor: pointer;
  padding: 1rem 1.3rem .6rem .75rem !important;

}


.label {
  font-size: .9rem !important;
  transform: unset !important;
  top: unset !important;
  left: unset !important;

  :focus {
    color: rgba(0, 0, 0, 0.6);
  }
}


.select {
  &>:focus {
    background: unset !important;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;