.block {
  --color-primary: #2E2E3D;
  --color-secondary: #7671DD;

  color: var(--color-primary);
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;

  &_isActive {
    color: var(--color-secondary);

    .icon {
      color: var(--color-secondary);
    }
  }
}


.icon {
  color: #7F8190;
  margin-right: .75rem;

  svg {
    width: 20px;
    height: 20px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;