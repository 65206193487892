.text {
  font-family: 'Arial', sans-serif;
  font-size: 17px;
  margin-top: 20px;
  font-weight: '700' !important;
  color: #7671DD;
}

.showRate {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #2e2e3d;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  transition: transform 0.3s;

  &:first-child {
    margin-bottom: 1rem;
  }

  &:hover {
    transform: scale(1.0);
    transition: transform 0.3s;
  }

  svg {
    margin-left: 13px;
  }
}


@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;