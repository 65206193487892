.scolarInfo {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  .scolarSideBar {
    background: #ffffff;
    box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
    border-radius: 15px;
    padding: 30px;
    height: 711px;
    width: 274px;
    overflow-x: scroll;
    position: relative;
    &::-webkit-scrollbar {
      width: 0;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      cursor: pointer;
      transition: all 0.4s;
      &:hover {
        color: #7671dd;
        transition: all 0.4s;
      }
    }
    .p-Active {
      color: #7671dd;
    }
  }
  .infoCardsWrap {
    width: 74%;
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 36px;
      color: #2e2e3d;
      margin-bottom: 20px;
    }
  }
  .infoCard {
    background: #ffffff;
    box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
    border-radius: 15px;
    padding: 10px 20px;
    margin-bottom: 10px;
    cursor: pointer;

    .circleIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      height: 27px;
      background-color: #F5F6FA;
      border-radius: 50px;
    }
    div {
      display: flex;
      justify-content: space-between;
    }
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #2e2e3d;
      margin: 0;
    }
    .openInfo {
      margin-top: 12px;
      height: fit-content;
      transition: all 0.4s;
    }
    .hiddenInfo {
      height: 0;
      transition: all 0.4s;
      overflow: hidden;
    }
    li {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
    }
  }
  .accordionFin {
    border-radius: 20px;
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
    display: none;
  }
  .accordionTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.vigorLayout {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 70.86%);
  border-radius: 15px;
  position: absolute;
  bottom: 0;
  height: 100px;
  width: 100%;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;