.workerGroups {
  .groupsTitlte {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #7f8191;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;