.collapsed-container {

  & :not(:first-child)::before {
    visibility: hidden;
  }

  &__accordion-summary> :first-child {
    margin: .5rem 0 !important;
  }

  &__accordion-details {

    &>* {
      margin-bottom: 1rem;
    }

    &>*:not(:nth-child(3n + 3)) {
      margin-right: 1rem;
    }

    // & :last-child {
    //   margin-right: 0;
    // }
  }


  &__icon-wrapper {
    align-items: center;
    background: #e9ebf3;
    border-radius: 1rem;
    display: flex;
    height: 4.5rem;
    justify-content: center;
    padding: .5rem;
    width: 4.5rem;
    margin-right: 1.9rem !important;
  }

  &__default-content {
    background: #f5f6fa;
    color: #7f8191;
    padding: 1.8rem;
    border-radius: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0.06rem;
    width: 100%;

    & a {
      color: #7671dd;
    }
  }

  &__header-text {
    padding-top: .25rem;
    margin-right: 1.5rem;

    &_subheader {
      color: #7f8190;
      font-weight: 600;
      min-width: fit-content;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;