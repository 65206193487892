.rangeField {
  padding: 9px 18px;
  background: #ffffff;
  border-radius: 15px;
  display: flex;
  &:hover {
    outline: 2px solid #c5c5c5;
  }
  .from {
    width: 50%;
    border-right: 1px solid #f5f6fa;
    .fromValue {
      display: flex;
      width: 100%;
      input {
        border: none;
        outline: none;
        max-width: 100%;
        padding: 0;
        &::placeholder {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 24px;
        }
      }
    }
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #7f8191;
      margin: 0;
    }
  }
  .to {
    border: none;
    margin-left: 20px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;