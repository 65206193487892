.effective-contract {
  --text-color-black: #2e2e3d;

  padding: 40px 84px 100px;
  display: flex;
  flex-direction: column;
  width: 1608px;
  margin: auto;

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 66px;
    color: var(--text-color-black);
    margin: 40px 0 20px 0;
  }

  &__subtitle {
    font-weight: bold;
    font-size: 1.45rem;
    letter-spacing: .1rem;
  }

  &__buttons-wrapper {
    margin-bottom: .6rem;

    & :not(:last-child) {
      margin: 0 .78rem .78rem 0;
    }
  }


  &__filters-wrapper {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    width: 100%;
    justify-content: space-between;
    margin-bottom: .45rem;

    &> :not(:last-child) {
      margin-bottom: .78rem;
    }
  }
}


@media all and (max-width: 1440px) {
  .effective-contract {
    width: 1000px;
    padding: 40px 8px 100px;
  }
}

@media all and (max-width: 1860px) {
  .effective-contract {
    width: 1400px;
  }
}

@media all and (max-width: 1650px) {
  .effective-contract {
    width: 1200px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;