.profile {
    padding: 40px 84px 100px;
    display: flex;
    flex-direction: column;
    width: 1608px;
    margin: auto;
}

.profileTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 66px;
    color: #2e2e3d;
    margin: 0;
    padding: 40px 0 20px 0;
}

.profileContent {
    padding: 60px;
    background: #ffffff;
    box-shadow: 0 12.52px 6.64px 0 rgba(0, 0, 0, 0.015);
    border-radius: 15px;
    .personalWrap {
        padding: 30px 15px 10px;
        border: 2px solid #f5f6fa;
        border-radius: 15px;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
    }
    .profileSubtitle {
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 36px;
        color: #2e2e3d;
        margin: 0;
        padding-bottom: 20px;
    }
}

.ant-col {
  position: static;
  margin-bottom: 15px;
}

.K-SaveBut {
  &:hover {
    transform: scale(1.05);
    transition: all 0.5s;
  }
}


@media all and (max-width: 1860px) {
  .profile {
  width: 1400px;
  }
}

@media all and (max-width: 1650px) {
  .profile {
  width: 1200px;
  }
}

@media all and (max-width: 1440px) {
  .profile {
  width: 1000px;
  }
  
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;