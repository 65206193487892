.generalHeader {
  background: #ffffff;
  padding: 30px;
  box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  .summoryTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: #2e2e3d;
    text-align: center;
    margin: 0;
  }
  .textSummory {
    background: #7671dd;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: #ffffff;
    padding: 8px 10px;
    margin-left: 5px;
  }
  .textSummoryWrap {
    display: flex;
    align-items: center;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;