.bookContent {
  background: #ffffff;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  border-radius: 15px;
  padding: 30px;
  margin-top: 20px;
}

.recFilterButtons {
  border: 2px solid #f5f6fa;
  box-sizing: border-box;
  border-radius: 20px;
  width: fit-content;
  margin-top: 40px;
  height: 62px;
  position: relative;
  .recFilterBut {
    border: none;
    height: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    border-radius: 20px;
    cursor: pointer;
    background: none;
  }
  .recButActive {
    color: white;
    border: none;
    background: #7671dd;
    box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
      0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
      0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
      0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
    color: white;
    border: none;
  }
}

.buttonsPanel {
  display: flex;
  justify-content: space-between;
}

.recCourseButtons {
  border: none;
  box-sizing: border-box;
  border-radius: 20px;
  width: fit-content;
  margin-top: 40px;
  height: 62px;
  .recButActive {
    background: #7671dd;
    box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
      0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
      0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
      0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
    color: white;
    border: none;
    &:hover {
      outline: none;
    }
  }
}

.courseBut {
  background: #ffffff;
  border: 2px solid #f5f6fa;
  height: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  border-radius: 20px;
  cursor: pointer;
  width: 120px;
  margin-right: 10px;
  &:disabled {
    background: #f5f6fa;
    color: #7f8191;
    border: none;
  }
  &:hover {
    outline: 2px solid #7671dd;
  }
}

.buttonHoverM {
  position: absolute;
  height: 62px;
  width: 204px;
  border-radius: 20px;
  background: #7671DD;
}

.buttonHoverC {
  position: absolute;
  height: 62px;
  border-radius: 20px;
  background: #7671DD;
  width: 104px;
  animation: translateM .5s linear;
  animation-fill-mode: forwards;
  //transform: translate(204px, 0px);
}

.buttonHoverP {
  position: absolute;
  height: 62px;
  border-radius: 20px;
  background: #7671DD;
  width: 104px;
  transform: translate(305px, 0px);
}

/* .translateM {
  animation: translateM 20s linear;
} */

@keyframes translateM {
  from{
    width: 204px;
    transform: translate(0px, 0px);
  }  
  to{
    width: 104px;
    transform: translate(204px, 0px);
  }

}

.App {
  text-align: center;
}

.marker {
  transition: all 1s ease-in-out;
  border-radius: 20px;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;