.darkLayer {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: #000000cc;
  z-index: 101;
  cursor: pointer;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;