.infoProject {

  .d-scrolloff::-webkit-scrollbar {
    height: 0;
  }

  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    margin: 60px 0 34px 0;
    color: #2e2e3d;
  }

  .steps {
    display: flex;
    background: white;
    width: fit-content;
    border-radius: 20px;
    max-width: 1400px;
    overflow: scroll;
    cursor: grabbing;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      width: 235px;
      height: 84px;
      margin-right: 2px;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #2e2e3d;
      border-radius: 20px;

      &:hover {
        background-image: url("../../../../../shared/assets/images/steps-back.png");
        background-size: 225px;
        background-repeat: no-repeat;
        background-position: right;
      }
    }
  }

  .projResults {
    padding: 30px;
    background: #ffffff;
    box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
    border-radius: 15px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h2 {
      margin: 0;
    }

    .descript {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #7f8191;
      margin: 20px 0;
    }

    .applyResponse {
      &__buttons-wrapper {
        display: flex;
        gap: 1.25rem;
      }

    }
  }

  .addResult {
    display: flex;
    justify-content: space-between;

    button {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all 0.4s;

      &:hover {
        transform: scale(1.05);
        transition: all 0.4s;
      }
    }

    svg {
      margin-left: 12px;
    }

    input {
      border: 2px solid #f5f6fa;
      box-sizing: border-box;
      border-radius: 15px;
      width: 80%;
      padding: 20px 15px;
      outline: none;
    }
  }

  .resultFileFilled,
  .resultFile {
    border: 2px dashed #f5f6fa;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    width: fit-content;
    justify-content: space-between;
    margin-bottom: 10px;

    .delete-icon {
      cursor: pointer;
    }

    div {
      display: flex;
    }

    p {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      margin: 0 30px 0 13px;
    }

    .activity {
      color: #7671dd;
      cursor: pointer;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      margin: 0 30px 0 13px;
    }
  }

  .resultFileFilled {
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    width: fit-content;
    justify-content: space-between;
    margin-bottom: 10px;

    svg {
      cursor: pointer;
    }

    div {
      display: flex;
    }

    p {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      margin: 0 12px 0 13px;
    }

    .activity {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      color: #2e2e3d;
    }
  }

  .resultsList {
    margin-top: 20px;
  }

  .projectCard {
    background: #ffffff;
    box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
    border-radius: 15px;
    padding: 30px;
    margin: 60px 0 20px 0;

    h3 {
      background: #f5f6fa;
      border-radius: 10px;
      width: fit-content;
      padding: 8px 10px;
      margin-bottom: 20px;
    }

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 36px;
      color: #2e2e3d;
      margin: 0 0 20px 0;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #7f8191;
    }
  }

  .disabled {
    background: #7F8191;
    transform: scale(1.0);
    transition: all 0.4s;

    &:hover {
      transform: scale(1.0);
      transition: all 0.4s;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;