.effective-contract-add-chievement {
  --text-color-black: #2e2e3d;
  --color-accent: #7671dd;

  padding: 40px 84px 100px;
  display: flex;
  flex-direction: column;
  width: 1608px;
  margin: auto;

  &__button-back {
    display: flex;
    align-items: flex-end;

    & :first-child {
      margin-right: 1rem;
    }

    &_accent {
      color: var(--color-accent);
    }
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 66px;
    color: var(--text-color-black);
    margin: 40px 0 20px 0;
  }

  &__subtitle {
    font-size: 2rem;
    font-weight: bold;
  }
}


@media all and (max-width: 1440px) {
  .effective-contract-add-chievement {
    width: 1000px;
    padding: 40px 8px 100px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;