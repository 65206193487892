.fileIcon {
  &_colorful {
    color: #7671DD;
  }

  &_pale {
    color: #C5C5C5;
  }
}


.inputFile {
  display: none;
}


.resultFile {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  width: fit-content;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 1.25rem;

  &_withoutFile {
    border: 2px dashed #f5f6fa;
  }

  &_withFile {
    border: 2px solid #f5f6fa;
  }
}


.titleWrapper {
  display: flex;
  gap: .5rem;
  align-items: flex-end;
}


.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #2e2e3d;
}


.label {
  cursor: pointer;
  font-style: normal;
  font-size: 1rem;
  margin: 0 30px 0 13px;

  &_withoutFile {
    font-weight: 700;
    color: #7671dd;
  }

  &_withFile {
    font-weight: 400;
    color: #2e2e3d;
  }
}


.deleteIcon {
  cursor: pointer;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;