.header {
  background: #ffffff;
  z-index: 100;
  position: fixed;
  right: 0;
  left: 0;
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 100px;
  padding-left: 312px;
  .errorNotify {
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #2e2e3d;
    display: flex;
    margin: 0 40px 0 0;
    transition: transform 0.4s;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
      transition: transform 0.4s;
    }
    svg {
      margin-left: 12px;
    }
  }
  .moodleLink {
    color: #2e2e3d;
    margin-left: 20px;
    display: flex;
    align-items: center;
    padding: 7px;
    border: 2px solid #f0f0f0;
    border-radius: 10px;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    cursor: pointer;
    transition: all .4s;
    img {
      margin-left: 6px;
      height: 30px;
      margin-bottom: 3px;
    }
    &:hover {
      color: #7671dd;
      transform: scale(1.07);
      transition: all .4s;
    }
  }
  .snowBoxWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      margin-right: 12px;
    }
  }
}

.hedaerWrap {
  height: 86px;
  .popup__outer-container {
    height: fit-content;
  }
}

.headerNav {
  display: flex;
  height: fit-content;
  width: fit-content;
  ul {
    display: flex;
    margin: 0;
    li {
      margin-right: 40px;
      &:last-child {
        margin: 0;
      }
    }
  }
}

.avatar {
  width: 24px;
  height: 24px;
  border-radius: 13px;
  border: 1px solid #2e2e3d;
}

.profileWrap {
  display: flex;
  align-items: center;
  margin-left: 32px;
  cursor: pointer;
}

.userName {
  margin: 0 18px 0 10px;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(46, 46, 61, 1);
  white-space: nowrap;
}

.errorWrap {
  display: flex;
  flex-direction: column;
  textarea {
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    border-radius: 15px;
    width: 100%;
    padding: 20px 15px;
    outline: none;
    margin: 30px 0 ;
    height: 142px;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    color: #7f8191;
  }
}

.PhoneInputCountryIcon {
  display: none;
}

.PhoneInputCountry {
  display: none;
}

.PhoneInputInput {
  background: #ffffff;
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    border-radius: 15px;
    height: 64px;
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #2e2e3d;
    padding-left: 15px;
    width: 100%;
    margin-bottom: 30px;
}


@media all and (max-width: 600px) {
  .header {
    padding-left: 30%;
  }
}

.errorTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
  color: #2e2e3d;
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;