.payment {
  .K-ChooseSpec {
    min-width: fit-content;
    margin: 20px 0;
    position: relative;
  }
  .scolTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 36px;
    color: #2e2e3d;
  }
}

.scol {
  .debt {
    background: #ff5e5e;
    border-radius: 30px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
  .acur {
    background: #00BA95;;
    border-radius: 30px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;