.confirmMob {
  .mobileRow {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    &:first-of-type {
      margin-bottom: 20px;
    }
  }
  .PhoneInputInput {
    margin: 0;
    padding: 1.2rem;
    height: fit-content;
  }
  .PhoneInput {
    width: 40%;
    margin: 0 20px 0 0;
  }

  .ant-input-number {
    height: 64px;
    width: 30%;
    margin: 0;
  }
  .codeMessage {
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
  }
}

.GetMfcApplication {
  .section-title {
    margin-top: 20px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;