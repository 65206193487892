.headerWrapper {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
}

.notificationsCount {
  background-color: #7671dd;
  width: 2rem;
  height: 2rem;
  padding: .5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: #fff;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;