.highEducation {
  .Navigation {
    margin: 0;
    justify-content: flex-start;
    .Card {
      margin-right: 25px;
      &:nth-of-type(3) {
        margin-right: 0;
      }
    }
  }

}

@media all and (max-width: 1860px) {
  .highEducation {
    .Navigation {
      .Card {
        margin-right: 25px;
        &:nth-of-type(3) {
          margin-right: 25px;
        }
      }
    }
  
  }
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;