.RelatedDiscipline {
  background: #f5f6fa;
  border-radius: 15px;
  width: 32%;
  padding: 30px;
  margin-bottom: 15px;
  .relatedTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #2e2e3d;
  }
  .relatedSubtitle {
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    color: #7f8191;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;