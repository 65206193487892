.projSchedule {
  margin-top: 60px;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  .addStep {
    display: flex;
    margin-top: 20px;
    input {
      border: 2px solid #f5f6fa;
      box-sizing: border-box;
      border-radius: 15px;
      padding: 20px 15px;
      outline: none;
      width: 60%;
      margin-right: 10px;
      &:nth-of-type(2) {
        width: 24%;
      }
    }
    .statusButton {
      display: flex;
      align-items: center;
      white-space: pre;
      &:disabled {
        background: #7F8190;
      }
      svg {
        margin-left: 10px;
      }
    }
  }
  h2 {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #2e2e3d;
  }
  .stepContainer {
    background: #f5f6fa;
    border-radius: 20px;
    max-width: 337.5px;
    padding: 20px;
    margin-right: 10px;
    margin-top: 20px;
    &:last-of-type {
      margin-right: 9px;
    }
    ul {
      margin-top: 20px;
    }
    .numberStage {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      color: #7f8191;
      margin: 0 0 5px 0;
    }
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #2e2e3d;
      margin-bottom: 5px;
      overflow: hidden;
    }
    .processProject {
      display: flex;
      align-items: center;
      p {
        margin: 0 10px 0 0;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: #2e2e3d;
      }
    }
    .scale {
      width: 187px;
      height: 6px;
      background: #ffffff;
      border-radius: 40px;

      div {
        height: 6px;
        background: #7671dd;
        border-radius: 40px;
      }
    }
  }
  .taskWrap {
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 5px;
    p {
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 20px;
    }
    .inProcess {
      color: #2e2e3d;
    }
    .failed {
      color: #7f8191;
    }
    .finished {
      color: #7671dd;
    }
    .h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      margin-bottom: 10px;
    }
    .date {
      background: #f5f6fa;
      border-radius: 10px;
      padding: 8px 10px;
      width: fit-content;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
    }
  }
  .plusStep {
    background: #ffffff;
    border-radius: 10px;
    padding: 12px 29px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    display: flex;
    align-items: center;
    margin-top: 20px;
    transition: all 0.4s;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
      transition: all 0.4s;
    }
    svg {
      margin-left: 12px;
    }
  }
  .dateWrap {
    width: 22%;
    margin-right: 15px;
    position: relative;
    margin-left: 10px;
    input {
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      color: #7f8191;
    }
    svg {
      position: absolute;
      bottom: 23px;
      right: 15px;
    }
  }
  .projSchedule .addStep button {
    display: none;
  }
  .dateWrap {
    position: relative;
    margin-right: 10px;
    input {
      width: 100%;
    }
    svg {
      position: absolute;
      bottom: 23px;
      right: 15px;
    }
  }
  .react-calendar {
    z-index: 2;
  }
}

@media all and (max-width: 1860px) {
  .projSchedule {
    .dateWrap {
     width: 26%
    }
    .addInput { 
      width: 40%;
    }
  }
}

@media all and (max-width: 1650px) {
  .projSchedule {
    .dateWrap {
     width: 26%;
     input {
      width: 100%;
    } 
    }
    .addInput { 
      width: 40%;
    }
  }
}

@media all and (max-width: 1440px) {
  .projSchedule {
    .dateWrap {
      input {
        width: 100%;
      } 
     width: 26%
    }
    .addInput { 
      width: 40%;
    }
  }
}

.stepCardHeader {
  display: flex;
  justify-content: space-between;
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;