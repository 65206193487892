.MiniNews {
  width: 100%;
  position: relative;

  .text-date-style {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.625rem;
    color: #7F8191;
  }

  .Main {
    .Info {
      .title {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 1.625rem;
        line-height: 2.25rem;
        color: #2E2E3D;
      }

      .description {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.625rem;
        color: #7F8191;
      }
    }
  }

  .n-blocks {
    .title {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: #2E2E3D;
      max-height: 112px;
      overflow: hidden;
    }
  }
}

.MiniNews .Main {
  cursor: pointer;

  width: 100%;
  height: 432px;

  background: #ffffff;
  border: 2px solid #f0f0f0;
  box-sizing: border-box;

  display: flex;
  align-items: center;

  overflow: hidden;
  /* card-shadow */

  box-shadow: 0px 12px 6px rgba(0, 0, 0, 0.04);
  border-radius: 25px;

  transition: 0.3s all ease;
}

/* .MiniNews .Main:hover {
  transform: scale(1.05);
} */

.MiniNews .Main .Image {
  width: 40%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  overflow: hidden;
}

.MiniNews .Main .Image img {
  // width: 100%;
  height: 100%;

  object-fit: fill;
}

.MiniNews .Main .Info {
  width: 60%;
  height: 60%;

  padding-left: 50px;
  padding-right: 50px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.MiniNews .Main .Button {
  padding: 18px;

  border: 1px solid #f0f0f0;

  border-radius: 15px;
  transition: 0.3s "background color" ease;

  cursor: pointer;
}

.MiniNews .Main .ActiveButton {
  padding: 18px;

  border: 1px solid #7671dd;

  border-radius: 15px;

  cursor: pointer;
  background: #7671dd;
  color: white;
  transition: 0.3s 'background color' ease;
}

.MiniNews .n-blocks {
  margin-top: 20px;
  width: 100%;
}

.MiniNews .n-block {
  cursor: pointer;
  width: 49%;
  background: #ffffff;
  border: 2px solid #f0f0f0;
  box-sizing: border-box;
  box-shadow: 0px 12px 6px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  transition: 0.3s all ease;
  justify-content: flex-start;

  @media screen and (max-width: 1600px) {
    width: 100%;
  }
}

.MiniNews .n-block:hover {
  transform: scale(1.05);
}

.MiniNews .n-block img {
  width: 238px;
  height: 207px;
  object-fit: cover;
  border-radius: 18px 0 0 18px;
}

.MiniNews .Open {
  cursor: pointer;
  padding: 18px;
  background: #ffffff;
  border-radius: 20px;
  margin: 40px 0 70px 0;
  width: 271px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  transition: 0.3s all ease;
  border: 2px solid #7671dd;
}

.More {
  height: 100px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.More .Open:hover {
  color: white;
  background-color: #7671dd;
  transform: scale(1.05);
}

@media screen and (max-width: 1440px) {
  .MiniNews .Main {
    width: 100%;
  }

  .MiniNews .n-block {
    height: 200px;
    overflow: hidden;
  }

  .MiniNews .n-block img {
    height: 100%;
    object-fit: cover;
  }

  .More {
    height: 70px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;