.buttonsWrapper {

  &>:first-child {
    margin-right: .5rem;
  }
}


.button {
  transition: transform .4s ease-in-out;

  &_edit {
    color: var(--text-color-edit);
  }

  &_delete {
    color: var(--text-color-delete);
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;