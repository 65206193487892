.applyCard {
  background: #ffffff;
  box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
  border-radius: 25px;
  padding: 30px;
  margin-top: 10px;
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 36px;
    color: #2e2e3d;
  }
  .teacher {
    display: flex;
    img {
      border-radius: 14px;
      width: 64px;
      height: 64px;
    }
    div {
      display: flex;
      flex-direction: column;
      margin-left: 13px;
    }
    .subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #7f8191;
      margin: 0;
    }
    .value {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #2e2e3d;
      font-style: normal;
      margin: 0 0 10px 0;
    }
  }
  .appr {
    background: #64d770;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: white;
    width: fit-content;
    padding: 8px 10px;
    height: fit-content;
    margin-left: 10px;
  }
  .appl {
    background: #F5F6FA;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #7F8191;
    width: fit-content;
    padding: 8px 10px;
    margin-left: 10px;
  }
  .rej {
    background: #FF5E5E;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
    width: fit-content;
    padding: 8px 10px;
    margin-left: 10px;
  }
  .applCardFoot {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      width: fit-content;
      align-self: flex-end;
      transition: all 0.4s;
      &:hover {
        transform: scale(1.05);
        transition: all 0.4s;
      }
    }
  }
  .decline {
    svg {
      margin-left: 12px;
    }
  }
  .downloadButton {
    background: #7671dd;
    box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
    0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
    0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
    0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
    color: white;
    margin-top: 10px;
    border-radius: 15px;
    width: 245px;
    height: 62px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    transition: all 0.4s;
    cursor: pointer;
    margin-right: 30px;

    &:hover {
      transform: scale(1.05);
      transition: all 0.4s;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;