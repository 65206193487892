.ShortAds {
    width: 100%;
}

.Carousel {
    width: 100%;
    height: 444px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.even {
    margin-left: 20px;
}

.ShortAds .Block {
    height: 27.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.875rem;
    background: #ffffff;
    border: 2px solid #f0f0f0;
    box-sizing: border-box;
    box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
    border-radius: 25px;
    transition: 0.3s all ease;
}

.ShortAds .Block .InnerBlock {
    width: 625px;
    height: 364px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.ShortAds .Block .InnerBlock .date {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.625rem;
}

.ShortAds .Block .InnerBlock h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 1.625rem;
    line-height: 2.25rem;
}

.ShortAds .Block .InnerBlock .desc {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.625rem;
    color: #7F8191;
    max-height: 120px;
    max-width: 647px;
    overflow: hidden;
}

.ShortAds .Block .InnerBlock .button {
    width: 208px;
    height: 60px;

    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;

    border: 2px solid #7671dd;
    box-sizing: border-box;
    border-radius: 20px;

    transition: 0.3s all ease;
}

.activeAds {
    width: 208px;
    height: 60px;

    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;

    border: 2px solid #7671dd;
    box-sizing: border-box;
    border-radius: 20px;

    transition: 0.3s all ease;
}

.activeAdsTrue {
    width: 208px;
    height: 60px;

    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;

    box-sizing: border-box;
    border-radius: 20px;

    cursor: pointer;
    color: white;
    background: #7671dd;
    transition: 0.3s all ease;
}

.ShortAds .Block .InnerBlock .button:hover {
    cursor: pointer;
    color: white;
    background: #7671dd;
    transition: 0.3s all ease;
}

.ShortAds .ArrowL {
    position: absolute;
    top: -60px;
    right: 110px;

    z-index: 10;
    cursor: pointer;
}

.ShortAds .Arrow {
    position: absolute;
    top: -60px;
    right: 0;

    z-index: 10;
    cursor: pointer;
}

.ShortAds .Row {
    display: flex;
    align-items: center;
}

.ShortAds .Swiper {
    height: 100%;
    width: 100px;

    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-right: 20px;
}

@media screen and (max-width: 1440px) {
    .ShortAds .Block {
        width: 450px;
        height: 400px;
    }

    .ShortAds .Block .InnerBlock {
        width: 410px;
        height: 350px;
    }

    .ShortAds .Block .InnerBlock .date {
        font-size: 12px;
    }

    .ShortAds .Block .InnerBlock h1 {
        font-size: 18px;
    }

    .ShortAds .Block .InnerBlock .desc {
        font-size: 12px;
    }

    .ShortAds .Block .InnerBlock .button {
        width: 150px;
        height: 35px;
        font-size: 12px;
    }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;