.ant-table-cell {
  vertical-align: top;
  &:nth-child(1) {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
  }
  &:nth-child(5) {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
  }
  &:nth-child(6) {
   word-break: break-word;
  }
  &:nth-child(7) {
    word-break: break-word;
   }
  &:last-child {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
  }
}

.ant-table-wrapper {
  outline: 2px solid #f5f6fa;
  box-sizing: border-box;
  border-radius: 15px;
  overflow: hidden;
  tr {
    th {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      background: #f5f6fa;
    }
  }
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.ant-table-pagination {
  display: none;
}

.semestrTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
  color: #2e2e3d;
  margin: 40px 0 10px 0;
}

.ant-table-tbody > tr > td {
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #2e2e3d;
}

.ant-table-thead > tr > th {
  border: none;
  padding-top: 16px;
}

.point {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 5px;
}

.gradeWrap {
  display: flex;
  align-items: center;
  .green {
    background: #42df83;
  }
  .yellow {
    background: #DFB342;
  }
  .red {
    background: #DF4242;
  }
}

@media all and (max-width: 1860px) {
  .recordBook {
  width: 1400px;
  }
  .ant-table-cell:nth-child(6) {
    word-break: normal;
}
.ant-table-cell:nth-child(7) {
  word-break: normal;
}
}

@media all and (max-width: 1650px) {
  .recordBook {
  width: 1200px;
  }
  .ant-table-cell:nth-child(6) {
    word-break: normal;
}
.ant-table-cell:nth-child(7) {
  word-break: normal;
}
.ant-table-content {
  overflow: scroll;
  &::-webkit-scrollbar { width: 0; }
}
}

@media all and (max-width: 1440px) {
  .recordBook {
  width: 1000px;
  }
  .ant-table-cell:nth-child(6) {
    word-break: normal;
}
.ant-table-cell:nth-child(7) {
  word-break: normal;
}
.ant-table-content {
  overflow: scroll;
  &::-webkit-scrollbar { width: 0; }
}
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;