.wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  button {
    margin: .5rem .625rem .5rem 0;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;