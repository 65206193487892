.bookData {
  background: #f5f6fa;
  border-radius: 20px;
  padding: 20px 20px 0 20px;
}

.infoValue {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #2e2e3d;
  margin: 0;
}

.recColTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #a4a6b3;
  margin: 0;
}

.recordCol {
  margin-bottom: 10px;
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;