.historyPayment {
  .debt {
    background: #ff5e5e;
    border-radius: 30px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .plus {
    background: #00BA95;
  }
  .ant-table-wrapper {
    margin-top: 20px;
    tr th {
      background: white;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #7f8191;
      border: 1px solid #e6e9f3;
    }
    tr td {
      border: 1px solid #e6e9f3;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #2e2e3d;
      padding: 16px;
    }
  }
  .histButs {
    margin-top: 40px;
    button {
      outline: none;
      background: #ffffff;
      border-radius: 20px;
      padding: 18px 24px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      margin-right: 10px;
      cursor: pointer;
      &:hover {
        outline: 2px solid #7671dd;
      }
    }
    .active {
      outline: none;
      background: #7671dd;
      box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
        0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
        0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
        0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
        color: white;
        &:hover {
          outline: none;
        }
    }
  }
}

.wantiki {
  display: flex;
  align-items: center;
  p {
    margin: 0 0 0 10px;
  }
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;