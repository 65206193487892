.FullCard {
  background: #ffffff;
  border-radius: 24px;
  padding: 40px;
  margin: auto;
  position: relative;
  width: 75%;
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .vigorLayout {
    bottom: 80px;
    height: 100px;
    width: 96%;
  }

  .ContentWrap {
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  h2 {
    margin-bottom: 30px;
  }

  .popupHeader {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 2px solid #f5f6fa;
  }

  .doButtons {
    display: flex;
  }

  .mainInfo {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #f5f6fa;
    margin-bottom: 20px;

    div {
      background: #f5f6fa;
      border-radius: 12px;
      padding: 10px 20px;
      min-width: 22%;
      max-width: 24%;

      h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        color: #7f8191;
        margin: 0;
      }

      p {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #2e2e3d;
        margin: 0;
      }
    }
  }

  .consalts {
    display: flex;
    flex-wrap: wrap;

    li {
      margin-bottom: 12px;
    }

    img {
      width: 24px;
      height: 24px;
      border-radius: 6px;
      margin: 0 10px 0 0;
    }

    div {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      margin-right: 15px;
    }
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #7f8191;
    margin-top: 20px;
  }

  .infoSection {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      overflow: hidden;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;