.K-WorkerHead {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #7f8191;
  margin-bottom: 40px;
}

.workerList {
  display: grid;
  grid-template-columns: repeat(auto-fill, 460px);
  gap: 15px 20px;
  margin: 0;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;