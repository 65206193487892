.achivmentCard {
  background: #ffffff;
  box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  padding: 30px;
  width: 460px;
  display: flex;
  min-height: 300px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s transform ease;

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.025);

    .arrowCircle {
      background: #7671DD;
    }

    .visitAchiv {
      color: #2e2e3d;
    }
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #2e2e3d;
  }

  .cardField {
    background: #f5f6fa;
    border-radius: 10px;
    padding: 8px 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: #2e2e3d;
    margin-top: 10px;
    width: fit-content;

    &_full-width {
      width: 100%;
    }
  }

  .visitAchiv {
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    color: #7f8191;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
    }
  }

  .arrowCircle {
    background: #F5F6FA;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;