.library__add-favorites-wrap {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #2e2e3d;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.4s ease;
  &:hover {
    transform: scale(1.02);
    transition: transform 0.4s ease;
  }
  svg {
    margin-left: 11px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;