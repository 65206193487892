.addAchivePopup {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  width: 100%;
  height: 100%;
  align-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 200;
  overflow: auto;

  .chooseField h4 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #7f8191;
  }

  .full-filled {
    width: 100%;
  }
}

.addAchive {
  background: #ffffff;
  border-radius: 24px;
  padding: 40px;
  margin: auto;
  width: 704px;
  display: flex;
  flex-direction: column;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;

    color: #2e2e3d;
  }

  .input-file {
    background-color: #f5f7ff;
  }

  .fileTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #2e2e3d;
    margin-bottom: 20px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;