.recordCard {
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  border-radius: 15px;
  width: 459px;
  margin-right: 30px;
  cursor: pointer;
  &:hover {
    outline: 2px solid #7671dd;
  }
  .cardTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    margin: 0;
    cursor: pointer;
  }
  .titleActive {
    color: #7671dd;
    cursor: pointer;
  }
  .department {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #7f8191;
    margin: 5px 0;
    cursor: pointer;
  }
  .subSpec {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #2e2e3d;
    margin: 0;
    cursor: pointer;
  }
  .spec {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .level {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #7f8191;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .downloadWrap {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    padding-left: 5px;
    &:hover {
      box-shadow: 0px 27px 46px rgb(118 113 221 / 12%),
        0px 8.13971px 13.8677px rgb(118 113 221 / 8%),
        0px 3.38082px 5.75991px rgb(118 113 221 / 6%),
        0px 1.22278px 2.08325px rgb(118 113 221 / 4%);
      border-radius: 10px;
    }
    .download {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #7671dd;
      margin: 0 10px 0 0;
      cursor: pointer;
    }
    .wrapIcon {
      background: #7671dd;
      border-radius: 10px;
      width: 32px;
      display: flex;
      height: 32px;
      align-items: center;
      justify-content: center;
    }
  }
}

.cardActive {
  outline: 2px solid #7671dd;
}


.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td { 
 padding: 0 16px 16px 16px;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;