.card-discipline {
  --text-color-black: #2e2e3d;
  --text-color-grey: #7f8190;
  --border-color-light: #f5f6fa;
  --card-hover-shadow: 0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024,
    0px 3px 14px 2px #0000001f;

  border-radius: 16px;
  border: 2px solid var(--border-color-light);
  color: var(--text-color-black);
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 2rem 1.5rem 1.5rem;
  width: 26rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    box-shadow: var(--card-hover-shadow);
  }

  &__title {
    font-size: 1.2rem;
    letter-spacing: 0.07rem;
    margin-bottom: 0.1rem;
  }

  &__subtitle,
  &__description {
    color: rgb(235, 87, 87);
    font-size: 1rem;
  }
}

.card-discipline__warn {
  border: 2px solid rgb(235, 87, 87);
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;