.banksWrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, 446.67px);
  gap: 20px;
  .bankCard {
    transition: all 0.4s;
    display: flex;
    background: #f5f6fa;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #2e2e3d;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
      transition: all 0.4s;
    }
    img {
      margin-right: 15px;
    }
  }
}

.QRS {
  background: #ffffff;
  box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  padding: 30px;
  margin-top: 10px;
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 36px;
    color: #2e2e3d;
  }
}

.QRSHidden {
  display: none;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;