.wrapper {
  --border-color: #E6E9F3;

  border-radius: .6rem;
  border: 1px solid var(--border-color);
  min-width: 100%;
  width: fit-content;
  position: inherit !important;
}

.wrapper>:nth-last-child(2) {
  visibility: unset !important;
}

.wrapper>:nth-last-child(2)>div {
  background-color: #00000066 !important;
  position: relative !important;
}

.wrapper>div:first-child {
  position: unset !important;
}

.table {
  font-size: .9rem;
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-bottom: 0.3rem;
  border-collapse: collapse;
}

.table td,
.table th {
  padding: .5rem;
}

.table>tbody>tr:hover {
  background-color: #7671dd2c;
}

.table>thead>tr th {
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  vertical-align: top;
}

.table>thead>tr>th:first-child {
  border-left: none;
}

.table>thead>tr>th:last-child {
  border-right: none;
}

.table>tbody>tr td {
  border-top: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  word-break: break-word;
}

.table>tbody>tr>td:first-child {
  border-left: none;
}

.table>tbody>tr>td:last-child {
  border-right: none;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;