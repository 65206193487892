.defaultPage {
  display: flex;
  flex-direction: column;
  height: 90vh;
  align-items: center;
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 66px;
    color: #2e2e3d;
    margin: 80px 0 0 0;
  }
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #7f8191;
    margin: 10px 0 20px 0;
  }
  img {
    margin-top: 30px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;