.inputsWrapper {
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;

  &>:nth-child(1n+1) {
    flex-grow: 1;
    height: auto;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;