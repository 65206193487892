.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;

  &_upper {
    z-index: 102;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.6rem 0;
  gap: 2rem;

  &_editMode {
    cursor: auto;
  }
}

.title {
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
  cursor: inherit;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;