.attachedFilesList {
  --text-color-dark: #2e2e3d;
  --text-color-light: #c5c5c5;
  --accent-color: #7671dd;
  padding-left: .15rem;
}


.icon {
  color: var(--accent-color);
  height: 1.25rem;
  width: 1.25rem;
  margin-right: .4rem;
}


.item {
  display: flex;
  align-items: center;
  color: var(--text-color-dark);
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: .028rem;
  margin-bottom: .5rem;
}


.link {
  color: var(--text-color-dark);
  margin-right: 1.1rem;
  cursor: pointer;

  &:hover {
    color: var(--accent-color)
  }
}


.removeFile {
  cursor: pointer;
  color: var(--text-color-light);
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;