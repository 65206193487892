.CourseWork {
  .termTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 36px;
    color: #2e2e3d;
    span {
      color: #2e2e3d;
    }
  }
  .ant-table-wrapper tr th {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #7f8191;
  }
  .ant-table-tbody > tr > td {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #2e2e3d;
  }
  .getRefBut {
    margin: 0;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;