.projectContainer {
  background: #ffffff;
  box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
  border-radius: 25px;
  padding: 30px;
  margin-top: 20px;
  position: relative;

  .aboutProject {
    overflow: hidden;
    width: 80%;
  }

  .vigor {
    position: absolute;
    width: 100%;
    height: 30px;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%);
  }

  .layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.8;
    z-index: 4;
    border-radius: 25px;
  }

  .prof {
    display: flex;
    align-items: center;

    .scale {
      width: 120px;
      height: 6px;
      background: #f5f6fa;
      border-radius: 40px;

      div {
        height: 6px;
        background: #7671dd;
        border-radius: 40px;
      }
    }

    .amount {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #2e2e3d;
      margin: 0 10px 0 30px;
    }

    .value {
      margin: 0;
    }
  }

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 36px;
    color: #2e2e3d;
    max-width: 95%;
    cursor: pointer;
  }

  .teacher {
    display: flex;

    img {
      border-radius: 14px;
      width: 64px;
      height: 64px;
      object-fit: cover;
    }

    div {
      display: flex;
      flex-direction: column;
      margin-left: 13px;
    }
  }

  .border {
    border-bottom: 2px solid #f5f6fa;
    margin: 20px 0;
  }

  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #7f8191;
    margin: 0;
  }

  .value {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #2e2e3d;
    font-style: normal;
    margin: 0 0 20px 0;
  }

  .valueBig {
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    color: #2e2e3d;
    font-style: normal;
    margin: 0 0 10px 0;
  }

  .general {
    display: flex;
    justify-content: space-between;
    position: relative;
    max-height: 10000px;
    transition: max-height 0.4s;
  }

  .deadLines {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;

    button {
      border-radius: 15px;
      width: 245px;
      height: 62px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;
    }

    .second {
      background: #7671dd;
      box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
        0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
        0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
        0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
      color: white;
      margin-top: 10px;
      cursor: pointer;
      transition: all 0.4s;

      &:hover {
        transform: scale(1.05);
        transition: all 0.4s;
      }
    }
  }

  .consalts {
    display: flex;
    flex-wrap: wrap;
    max-width: 860px;

    li {
      margin-bottom: 12px;
    }

    img {
      width: 24px;
      height: 24px;
      border-radius: 6px;
      margin: 0 10px 0 0;
    }

    div {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      margin-right: 15px;
    }
  }

  .customer {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #2e2e3d;

    img {
      width: 32px;
      height: 32px;
      border-radius: 6px;
      margin-right: 10px;
    }
  }

  .hashtegs {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    div {
      border: 2px solid #f5f6fa;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 8px 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #7f8191;
      margin-right: 10px;
    }
  }

  .scale {
    width: 120px;
    height: 6px;
    background: #f5f6fa;
    border-radius: 40px;

    div {
      height: 6px;
      background: #7671dd;
      border-radius: 40px;
    }
  }

  .amount {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #2e2e3d;
    margin: 0 10px 0 0;
  }

  .value {
    margin: 0 0 20px 0;
  }

  .part {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  .confirm {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    margin: 20px 0;
  }

  .popup__outer-container {
    height: fit-content;
  }
}

.projectFull {
  //transition: all 0.4s;
  overflow: hidden;
  max-height: 291px;
  display: flex;
  justify-content: space-between;
  position: relative;
  transition: max-height 0.3s;
}

.cardFooter {
  display: flex;
  justify-content: space-between;

  .projectWork {
    z-index: 1;
  }

  button {
    background: #7671dd;
    box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
      0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
      0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
      0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
    color: white;
    margin-top: 10px;
    border-radius: 15px;
    width: 245px;
    height: 62px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    transition: all 0.4s;
    cursor: pointer;
    margin-right: 30px;

    &:hover {
      transform: scale(1.05);
      transition: all 0.4s;
    }
  }
}

.workTypeTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #7f8191;
  align-self: end;
  margin: 0 0 0 51.5%;
}

.projectCategory {
  background-color: #00ba95;
  color: white;
  padding: 20px;
  border-radius: 10px;
  font-weight: bold;
  width: 250px;
}

.projectCategoryForeign {
  background-color: #DF4242;
  color: white;
  padding: 20px;
  border-radius: 10px;
  font-weight: bold;
  width: 250px;
}

.downloadButton {
  background: #7671dd;
  box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
  0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
  0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
  0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
  color: white;
  margin-top: 10px;
  border-radius: 15px;
  width: 245px;
  height: 62px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  transition: all 0.4s;
  cursor: pointer;
  margin-right: 30px;

  &:hover {
    transform: scale(1.05);
    transition: all 0.4s;
  }
}

.text {
  font-family: 'Arial', sans-serif;
  font-size: 17px;
  margin-right: 5px;
  font-weight: '700' !important;
  color: white;
}

.button {
  background-color: #7671DD;
  min-width: 220px;
  min-height: 62px;
  margin-bottom: 20px;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;