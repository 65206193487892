.input {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: .035rem;
  width: 100%;
  line-height: 1.5;
  border: none;
  outline: none;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;