.d-path {
  margin: 10px 0;

  font-size: 16px;
  color: #7f8191;
}

.active {
  color: #7671dd;
}

.d-h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 66px;
  color: #2e2e3d;
  margin: 0;
  padding: 40px 0 20px 0;
}

.d-row {
  display: flex;
  flex-direction: row;
}

.d-space {
  justify-content: space-between;
}

.d-center {
  align-items: center;
  justify-content: center;
}

.d-arrow-bg {
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  background: #ffffff;
}

.d-arrow-bg-active {
  background: #7671dd;
}

.d-arrow-to-left {
  transform: scale(-1, -1);
}

.d-red {
  color: #eb5757;
}

.p-20 {
  padding: 20px;
}

.p-10 {
  padding: 10px;
}

.d-select {
  padding: 18px;
  border: 0;
  outline: none;
  background: #ffffff;
  border-radius: 15px;

  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
}

.d-cursor {
  cursor: pointer;
}

.d-scrolloff {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.d-scrolloff::-webkit-scrollbar {
  width: 0;
}

// ---- News Styles ---- //

.n-blocks {
  width: 960px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  justify-content: space-between;
}

.n-block {
  width: 460px;
  border-radius: 18px;

  margin-bottom: 30px;
}

.n-img {
  width: 100%;
  height: 207px;
  border-radius: 25px;
  overflow: hidden;
}

.n-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.n-date {
  font-weight: normal;
  color: #7f8191;
  padding: 10px 0;
}

.n-h3 {
  font-size: 20px;
  color: #2e2e3d;

  padding: 10px 0;
}

.n-desc {
  font-size: 20px;
  color: #2e2e3d;

  padding: 10px 0;
}

// ---- News Styles ---- //

// ---- ScrollBar ---- //

.d-scrollbar {
  scrollbar-color: #e2e5f1;
  /* #7671dd #f5f7ff */
  /* «цвет ползунка» «цвет полосы скроллбара» */
  /* scrollbar-width: thin; */
}

/* полоса прокрутки (скроллбар) */
.d-scrollbar ::-webkit-scrollbar {
  /* height: 20px; */
  width: 20px;
  /* ширина для вертикального скролла */
  /* background-color: white;  */
  border: 1px solid black;
}

.d-scrollbar ::-webkit-scrollbar-track {
  background-color: white;
  /* width: 10px;
  height: 10px;
  margin: 10px 10px; */
}

/* ползунок скроллбара */
.d-scrollbar ::-webkit-scrollbar-thumb {
  /* width: 5px;*/
  //height: 8px;
  /* margin: 10px; */
  cursor: pointer;
  background-color: #e2e5f1;
  border: 6px solid transparent;
  /* #7671dd */
  border-radius: 10px;
  background-clip: content-box;
}

/* Стрелки */

.d-scrollbar ::-webkit-scrollbar-button:vertical:start:decrement {
  display: none;
}

.d-scrollbar ::-webkit-scrollbar-button:vertical:end:increment {
  display: none;
}

.d-scrollbar ::-webkit-scrollbar-button:horizontal:start:decrement {
  display: none;
}

.d-scrollbar ::-webkit-scrollbar-button:horizontal:end:increment {
  display: none;
}

.page-main-title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 66px;
  color: #2e2e3d;
  padding: 40px 0 20px 0;
}

.section-title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #2e2e3d;
}

// ---- ScrollBar ---- //

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;