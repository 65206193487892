.Debt {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  padding: 30px;

  background: #ffffff;
  border: 2px solid #f0f0f0;
  box-sizing: border-box;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  border-radius: 25px;

  .payButtons {
    button {
      min-width: 338px;
      max-width: fit-content;
      white-space: nowrap;
      margin-right: 10px;
    }
  }

  .mainContracts {
    display: flex;
    flex-wrap: wrap;
  }

  .active {
    width: 400px;
    margin-right: 10px;
    border-radius: 25px;
    margin-bottom: 15px;
    padding: 30px;
    outline: 2px solid #7671dd;
    background: white;

    h2 {
      padding: 0;
      margin: 0;
      font-weight: 700;
      font-size: 20px;
    }

    span {
      font-weight: 600;
      color: grey;
    }
  }

  &__card-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;

    @media screen and (max-width: 1440px) {
      justify-content: space-between;
    }
  }
}

.Debt .Contract {
  width: 400px;
  margin-right: 10px;
  border-radius: 25px;
  background: #f5f6fa;
  padding: 30px;
  margin-bottom: 15px;
  cursor: pointer;

  &:hover {
    outline: #e6e9f3 2px solid;
    background: white;
  }
}

.Debt .Contract h2 {
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-size: 20px;
}

.Debt .Contract span {
  font-weight: 600;
  color: grey;
}

.Debt span {
  font-weight: 600;
  color: grey;
}

.Debt .OuterBlock {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Debt .InnerBlock div {
  font-weight: 600;
  color: grey;
}

.Debt .Block {
  width: 440px;
  height: 260px;

  padding: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  border: 3px solid #f9f9f9;
  border-radius: 25px;
}

.Debt .Block .InnerBlock {
  width: 100%;
}

.Debt .Block .ToDown {
  display: flex;
  align-items: flex-end;
}

.Debt .Block .Icon {
  width: 64px;
  height: 64px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #00ba95;
  border-radius: 50%;
}

.Debt .Block .Reverse {
  transform: scale(-1, -1);
  background: #eb5757;
}

.Debt .Price {
  font-weight: 800;
}

.f-26 {
  font-size: 26px;
}

.Debt .Block .InnerBlock .Peni {
  background: #fc8d3b;
}

.Debt .Warning {
  width: 24px;
  height: 24px;

  margin-left: 5px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #eb5757;
  border-radius: 50%;
}

.Debt .Warning::before {
  content: "!";
  color: white;
  font-style: normal;
  font-size: 15px;
  line-height: 24px;
}

.Debt .Choose {
  width: 100%;
  height: 76px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Debt .Choose div {
  width: 330px;
  height: 76px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding: 15px;

  border: 2px solid #f0f0f0;
  border-radius: 25px;
}

.Debt .Choose div:hover {
  background: #7671dd;
}

.Debt .Choose div h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;

  padding: 0;
  padding-left: 15px;
  margin: 0;
}

.Debt .Choose .Active {
  border: none;
  background: #7671dd;
}

.Debt .Choose .ActiveH2 {
  color: white;
}

@media screen and (max-width: 1440px) {
  .Debt .Contract {
    width: 300px;
    height: 70px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f5f6fa;
  }

  .Debt .Contract h2 {
    font-size: 12px;
  }

  .Debt .Block {
    width: 330px;
    height: 200px;

    padding: 20px;
  }

  .Debt .Block .Icon {
    width: 40px;
    height: 40px;
  }

  .Debt .Choose div {
    width: 220px;
    height: 48px;
  }

  .Debt .Choose div h2 {
    font-size: 12px;
    line-height: 12px;
  }
}

.Debt .d-red {
  color: #eb5757;
  font-weight: 700;
}

.finTitleWrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
  align-items: center;

  button {
    height: 62px;
    width: 167px;
    background: #ffffff;
    border-radius: 20px;
    font-style: normal;
    transition: all 0.4s;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #2e2e3d;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #7671dd;
    cursor: pointer;

    .icon-tabler-arrow-right {
      color: #7671DD;
      margin-left: 12px;
    }

    &:hover {
      transform: scale(1.04);
      transition: all 0.4s;
      background-color: #7671DD;
      color: white;

      .icon-tabler-arrow-right {
        color: white;
      }
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;