.notificationItem {
  padding: 1.5rem;
  border: solid 3px #F5F6FA;
  border-radius: 1.25rem;
}

.header {
  display: flex;
  justify-content: space-between;
}

.statusWrapper {
  display: flex;
}

.optionsButton {
  margin-right: 0 !important;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding-right: 1.5rem;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;