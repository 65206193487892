.paymentFrame {
  background: #ffffff;
  box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  padding: 30px;
  margin-top: 20px;
  .paymentInfo {
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 30px;
    margin-top: 20px;
    .penalti {
      margin: 0;
      display: flex;
      color: #ff5e5e;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 24px;
    }
    .warn {
      width: 24px;
      height: 24px;
      background: #ff5e5e;
      border-radius: 30px;
      margin-left: 8px;
      color: white;
      display: flex;
      justify-content: center;
    }
    .infoGrid {
      display: grid;
      grid-template-columns: repeat(auto-fill, 425px);
      grid-gap: 15px 20px;
      gap: 15px 20px;
      margin: 0;
      justify-content: center;

      .debt {
        background: #ff5e5e;
        border-radius: 30px;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
      }
      .plus {
        background: #00BA95;
      }
      .big {
        font-weight: 700;
        font-size: 26px;
        line-height: 36px;
      }
      h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        color: #7f8191;
      }
      .value {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: black;
        display: flex;
        align-items: center;
      }
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 36px;
      color: #2E2E3D;
    }
  }
  .contractWrap {
    outline: none;
    background: #f5f6fa;
    border-radius: 25px;
    padding: 30px;
    margin-right: 20px;
    cursor: pointer;
    &:hover {
      outline: #E6E9F3 2px solid ;
      background: white;
    }
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #2e2e3d;
    }
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      color: #7f8191;
      margin: 0;
    }
  }
  .wrapActive {
    outline: 2px solid #7671dd;
    background: white;
    &:hover {
      outline: 2px solid #7671dd;
    }
  }
}

.footerTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  margin: 40px 0 140px 0;
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;