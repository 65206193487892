.trecksListContainer {
  padding: 20px;
  margin-top: 10px;
}

.yearContainer {
  margin-bottom: 20px;
  border: 1px #ddd;
  border-radius: 8px;
  overflow: hidden;
  min-width: 1340px;
}

.yearHeader {
  background-color: #f5f6fa;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  transition: background-color 0.3s ease;
}

.yearHeader:hover {
  background-color: #f5f6fa;
}

.tracksContainer {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
}


@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;