.detailStatsPopupTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
  color: #2e2e3d;
}

.statsItemWrap {
  border: 2px solid #f5f6fa;
  border-radius: 20px;
  padding: 30px;
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #2e2e3d;
  }
}

.statsDetailWrap {
  display: flex;
  justify-content: space-between;
  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #7f8191;
  }
  p {
    color: #2E2E3D;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #7f8191;
  }
  div {
    width: 33%;
  }
}

.discipline-detail-card__statistics{
  .popup__outer-container{
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;