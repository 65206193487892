.custom {
  color: rgba(255, 255, 255, 0.9);
}

.controls {
  height: 80px;
  position: fixed;
  bottom: 21%;
  right: 26%;
}
.redactorWrap {
  display: flex;
  justify-content: center;
}

.controlsUp {
  display: flex;
}

.cropContainer {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.slider {
  width: 100%;
}

.save {
  padding: 10px;
  margin-left: 20px;
  background: #7671dd;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  border-radius: 20px;
  cursor: pointer;
  width: 189px;
  height: 62px;
}

.reactEasyCrop_Container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  border-radius: 15px;
  overflow: hidden;
  width: 50%;
  margin: auto;
  height: 40%;
  user-select: none;
  touch-action: none;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeButton {
  background: #7671dd;
  box-shadow: 0px 12.5216px 6.63642px rgb(0 0 0 / 2%);
  border-radius: 10px;
  width: 64px;
  height: 64px;
  position: fixed;
  top: 24%;
  color: white;
  right: 25%;
  font-size: 34px;
  cursor: pointer;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;