.button-simple {
  --accent-color: #7671dd;
  --button-light-color: #fff;
  --button-active-color: #7671dd;
  --text-color-light: #fff;
  --border-color-alone: #f0f0f0;
  --box-shadow: 0px 1.222775936126709px 2.0832479000091553px 0px #7671dd0b,
    0px 3.380819082260132px 5.759913921356201px 0px #7671dd0f,
    0px 8.13970947265625px 13.867653846740723px 0px #7671dd14,
    0px 27px 46px 0px #7671dd1f;

  background: var(--button-light-color);
  border-radius: 1rem;
  border: none;
  cursor: pointer;
  font-weight: bold;
  height: 100%;
  height: fit-content;
  letter-spacing: 0.092rem;
  padding: 1.125rem 1.5rem;

  &:hover {
    box-shadow: 0 0 1px 2px var(--button-active-color);
  }

  &_active,
  &_filled,
  &_full-filled {
    background: var(--button-active-color);
    color: var(--text-color-light);
    box-shadow: var(--box-shadow);

    &:hover {
      box-shadow: var(--box-shadow);
    }

    & .button-simple__icon-component-icon {
      color: inherit;
    }
  }

  &_filled,
  &_full-filled,
  &_alone {
    transition: transform .4s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  &_full-filled {
    width: 100%;
  }

  &_alone,
  &_groupe {
    border: 2px solid var(--border-color-alone);
  }

  &_groupe {
    transition: unset;
  }

  &_disabled {
    background-color: #fff;
    color: #7f8191;
    cursor: not-allowed;
    box-shadow: none;

    &:hover {
      transform: none;
    }
  }

  &_small-button {
    font-size: .88rem;
    padding: .75rem 1rem;
  }

  &_transparent {
    background-color: transparent;
  }

  &__icon-component {
    display: flex;
    align-items: flex-end;
    line-height: 1.5;
  }

  &__icon-component-icon {
    margin: 0 0 0 .8rem;
    color: var(--accent-color);
  }

  &_active {
    border-color: var(--button-active-color);
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;