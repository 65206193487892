.form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;