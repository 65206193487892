.PortfolioScale {
  background: #ffffff;
  box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  padding: 30px;
  margin: 20px 0 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ratingBar {
    width: 300px;
    height: 300px;
    position: relative;

    .summoryTitle {
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      color: #2e2e3d;
      max-width: 102px;
      text-align: center;
      margin: 0;
    }

    .textSummory {
      background: #7671dd;
      border-radius: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      color: #ffffff;
      max-width: 115px;
      padding: 8px 10px;
      margin-top: 10px;
    }

    .textSummoryWrap {
      position: absolute;
      left: 31%;
      top: 33%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .indicator-circul {
    border-radius: 50%;
    display: inline-block;
    height: 0.8rem;
    margin-right: 0.55rem;
    width: 0.8rem;

    &_basic-indicators {
      background: #7671dd;
      border: 0.1rem solid #5d58be;
    }

    &_hight-performance {
      background: #f9b754;
      border: 0.1rem solid #c59a5b;
    }

    &_bonus-points {
      background: #43d65a;
      border: 0.1rem solid #5fbc6d;
    }

    &_downgrade-payment {
      background: #ef5d5d;
      border: 0.1rem solid #ba5757;
    }
  }

  .summoryList {
    margin: 0 66px 0 25px;
  }

  .ant-switch-checked {
    background-color: #7671dd;
  }

  .switchWrap {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: #2e2e3d;
    display: flex;
    align-items: center;
    margin-top: 30px;
  }

  .ant-switch {
    margin-left: 10px;
  }

  .interactiveSide {
    margin-left: 66px;
    display: flex;
    flex-direction: column;
    height: 280px;
    justify-content: space-between;
  }

  .rate-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}


.showRate {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #2e2e3d;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  transition: transform 0.3s;

  &:first-child {
    margin-bottom: 1rem;
  }

  &:hover {
    transform: scale(1.05);
    transition: transform 0.3s;
  }

  svg {
    margin-left: 13px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;