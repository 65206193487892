.allProjects {
  .filtersHid {
    height: 0;
    transition: all 0.4s;
    overflow: hidden;
  }

  .pagination-simple {
    & :first-child>li {
      padding: 0;
      z-index: 2;
    }
  }




  .filterWrap {
    display: flex;
    margin-top: 20px;
    align-items: center;
    //justify-content: space-between;

    input {
      background: #ffffff;
      border-radius: 15px;
      width: 67%;
      height: 62px;
      border: none;
      outline: none;
      padding-left: 15px;
      margin-right: 20px;

      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 24px;
        color: #7f8191;
      }
    }

    p {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      margin: 0;
      display: flex;
      align-items: center;
      white-space: nowrap;
      min-width: 169px;
    }

    svg {
      cursor: pointer;
      width: 29px;
      margin-left: 12px;
    }

    .init {
      width: 269px;
    }

    .searchBut {
      background: #7671dd;
      box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
        0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
        0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
        0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
      border-radius: 15px;
      width: 215px;
      height: 62px;
      color: white;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      margin-left: 32px;
      cursor: pointer;
      transition: all 0.4s;

      &:hover {
        transform: scale(1.05);
        transition: all 0.4s;
      }
    }
  }

  .filterFields {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    height: 264px;
    transition: all 0.4s;
  }

  .chooseField {
    padding: 12px 30px 12px 13px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    border-radius: 15px;
    min-height: 75px;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-right: 0;
    background: white;
    margin-top: 10px;

    &:hover {
      outline: 2px solid #c5c5c5;
    }

    h4 {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      color: #7f8191;
      margin: 0;
    }

    .fieldValue {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      color: #2e2e3d;
    }
  }

  .inp {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    input {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      color: #24252e;
      border: none;
      outline: none;
      padding: 0;
      width: 100%;
      background-color: inherit;

      &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        color: #24252e;
      }
    }
  }

  .chooseActive {
    outline: 2px solid #7671dd;

    &:hover {
      outline: 2px solid #7671dd;
    }
  }

  .clear {
    width: fit-content;
    align-self: end;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #c5c5c5;
    display: flex;
    align-items: center;
    transition: all 0.4s;
    margin-top: 20px;
    cursor: pointer;

    svg {
      margin-left: 20px;
    }

    &:hover {
      transform: scale(1.05);
      transition: all 0.4s;
    }
  }

  .inp {
    &:focus {
      outline: 2px solid #7671dd;
    }
  }

  .dateWrap {
    position: relative;

    svg {
      position: absolute;
      bottom: 29px;
      right: 15px;
    }
  }

  .react-calendar {
    z-index: 6;
    bottom: -305px;
  }

  .multiple {
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 0px 15px;
    outline: none;
    margin: 10px 10px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    color: #24252e;
    min-height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 15px;

    span {
      display: flex;
    }

    input {
      border: none;
      margin: 0;
      width: 100%;
      height: 100%;
      outline: none;

      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 24px;
        color: #7f8191;
        margin: 0;
      }
    }

    svg {
      position: initial;
    }

    .specTag {
      display: flex;
      background: #f5f6fa;
      border-radius: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      margin-right: 10px;
      padding: 7px;

      svg {
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }

  .inpTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    color: #7f8191;
    margin: 0;
  }

  .pagination-simple {
    width: fit-content;
    margin-top: 40px;
  }

  .pagination-simple__background {
    width: 79%;
  }

  .additionalFilters {
    display: flex;
    align-items: center;
    margin: 20px 0 0 0;
  }

  .filtersBottom {
    display: flex;
    justify-content: space-between;

    .StatusCheckboxWrap {
      display: flex;
    }
  }
}

.ant-input-number {
  height: 75px;
  margin-top: 10px;
  border: 2px solid #f5f6fa;
  border-radius: 15px;
  outline: none;
  width: 100%;
}

.ant-input-number-input-wrap {
  height: 100%;
  padding: 12px 30px 12px 13px;
  background: white;
  border-radius: 15px;
}

.ant-input-number-input {
  height: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #24252e;
  border: none;
  outline: none;
  padding: 0;
  background-color: inherit;
}

.ant-input-number-focused {
  border: none;
  outline: none;
}

.ant-input-number:hover {
  border: none;
}

.ant-input-number-handler-wrap {
  border-radius: 20px;
}

.k-dateSort {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #2e2e3d;
  margin: 0 20px 0 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    svg {
      transform: scale(1.08);
      transition: transform 0.4s;
    }
  }

  svg {
    margin-right: 13px;
    transition: transform 0.4s;
  }
}

.choosePageWrap {
  .ant-input-number {
    width: 30%;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;