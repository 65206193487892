.library-card {
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
  border-radius: 25px;
  padding: 30px;
  display: flex;
  .book-cover {
    height: 240px;
    width: 240px;
    border-radius: 15px;
    margin-right: 30px;
  }
  .library-card__title {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    color: #2e2e3d;
  }
  .library-card__author {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #7f8191;
  }
  .library-card__description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
  }
  .library-card__book-content{
    margin-top: 20px;
  }
}

.library-card_hover {
  cursor: pointer;
  transition: transform 0.4s ease;
  &:hover {
    transform: scale(1.02);
    transition: transform 0.4s ease;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;