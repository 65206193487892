.pre-loader {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-ol7wxs-MuiTypography-root {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #7671dd;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;