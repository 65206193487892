.effective-contract {
  --border-color-acent: #F9B754;
  --border-color-acent-light: #FFEFD8;


  &__visualize-data {
    background: #fff;
    border-radius: .8rem;
    box-shadow: 0px 12.5216px 6.63642px #00000004;
    color: #2e2e3d;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    overflow: hidden;
    padding: 1.1rem 1.8rem;
    margin-bottom: 3.9rem;


    &_acent {
      border: .25rem solid var(--border-color-acent);
    }
  }

  &__radial-bar-with-description {
    display: flex;
  }

  &__radial-bar-wrapper {
    height: 19rem;
    position: relative;
    transform: translateX(-5rem) scale(2);
    width: 10rem;
  }

  &__radial-bar-wrapper-text-block {
    padding: 2rem .95rem 1rem .95rem;
  }

  &__persondata-item {
      border-radius: .6rem;
      font-size: 1.535rem;
      font-weight: 600;
      margin-bottom: .7rem;
      padding: .32rem .6rem;
      width: 100%;
  }

  &__description-item {
    background: #f5f6fa;
    border-radius: .6rem;
    font-size: 1.135rem;
    font-weight: 600;
    margin-bottom: .7rem;
    padding: .32rem .6rem;
    width: 100%;


    &_acent {
      background: var(--border-color-acent-light);
    }
  }

  & .indicator-circul {
    border-radius: 50%;
    display: inline-block;
    height: .8rem;
    margin-right: .55rem;
    width: .8rem;

    &_basic-indicators {
      background: #7671dd;
      border: .1rem solid #5d58be;
    }

    &_hight-performance {
      background: #f9b754;
      border: .1rem solid #c59a5b;
    }

    &_bonus-points {
      background: #43d65a;
      border: .1rem solid #5fbc6d;
    }

    &_downgrade-payment {
      background: #ef5d5d;
      border: .1rem solid #ba5757;
    }
  }

  &__visualize-data-button-wrapper {
    padding: .8rem .2rem;
    z-index: 1;
  }


  &__visualize-data-accent-message-wrapper {
    background: #F5F6FA;
    width: 26.8rem;
    height: fit-content;
    margin-top: 2rem;
    align-self: flex-end;
    padding: 1.2rem 2.08rem;
    font-weight: 700;
    letter-spacing: 0.09rem;
    line-height: 1.6rem;
    text-align: center;
    border-radius: 1rem;
  }
  &__visualize-data-accent-message {
    width: 26.8rem;
    height: fit-content;
    margin-top: 2rem;
    align-self: flex-end;
    padding: 1.2rem 2.08rem;
    font-weight: 700;
    letter-spacing: 0.09rem;
    line-height: 1.6rem;
    text-align: center;
    border-radius: 1rem;
  }
  &__ref-container {
    display: flex;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px; 
  }
  
  &__ref {
    text-decoration: underline;
  }
}


@media all and (max-width: 1440px) {
  .effective-contract {

    &__radial-bar-with-description {
      width: 75%;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;