@activeBackground: rgba(245, 247, 255, 1);
@activeContent: rgba(118, 113, 221, 1);



.itemWrapOpen {
  background: @activeBackground;
  color: @activeContent;
  border-left: 3px solid;
  box-shadow: 0 6px 18px 0 rgb(54 55 64 / 10%);
}

.mainItemSecond {
  font-size: 13px;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
}

.chevron {
  position: absolute;
  right: 22px;
  transition: opacity .5s linear;
  opacity: 1;
}

.chevronHidden {
  right: 10px
}

.itemWrap {
  svg{
    margin-left: 30px;
  }
  &:hover {
    background: @activeBackground;
  color: @activeContent;
  border-left: 3px solid;
  box-shadow: 0 6px 18px 0 rgb(54 55 64 / 10%);
  }
}

.hide {
  display: none;
}


  
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;