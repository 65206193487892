.refPopup {
  background: #ffffff;
  border: 2px solid #f5f6fa;
  box-sizing: border-box;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  border-radius: 20px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 64px;
  padding: 10px 0 20px 0;
  z-index: 5;
  .refPopupItem {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    padding: 10px;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      background: #f5f6fa;
    }
  }
}

.hidden {
  display: none;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;