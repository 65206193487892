.card {
  background-color: #F5F6FA;
  padding: 1.2rem 1rem;
  border-radius: .8rem;
  width: 28.1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 1860px) {
    width: 23.8rem;
  }

  @media screen and (max-width: 1440px) {
    width: 15.4rem;
  }
}

.title {
  font-weight: 700;
  font-size: 1.5rem;

  &_small {
    font-size: 1rem;
  }
}

.description {
  font-size: 1rem;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;