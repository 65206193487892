.deleteIcon {
  color: #FF5E5E;
  height: 2rem;
  width: 2rem;
  position: absolute;
  transform: translate(-5.5rem, -0.2rem);
  cursor: pointer;
}

.deleteIconMin {
  color: #FF5E5E;
  height: 2rem;
  width: 2rem;
  position: absolute;
  transform: translate(-4rem, -0.2rem);
  cursor: pointer;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;