.learning-pathway-tab {
  background: #fff;
  box-shadow: 0px 12.521552085876465px 6.636422634124756px 0px #00000004;
  padding: 2rem 1.8rem;
  border-radius: 1rem;
  color: #2e2e3d;

  &__header {
    font-size: 1rem;
  }

  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: .12rem;
  }

  &__subtitle {
    color: #7f8191;
    margin-bottom: 0;
  }

  &__description {
    line-height: 1.6;
    margin-bottom: 1.2rem;
  }

  &__description-textarea {
    width: 100%;
    border-radius: 1rem;
    border: 2px solid #f5f6fa;
    padding: 1rem;
    outline: none;
  }

  &__attached-files-wrapper {

    &_edit {

      & > ul {
        display: flex;
        flex-wrap: wrap;

        & > li {
          margin-right: 1.5rem;

          & > a :first-child {
            max-width: 7rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  &__attached-buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 1rem;

    & :first-child {
      margin-right: .6rem;
    }
  }

  &__cards-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, 28rem);
    column-gap: 1%;

   /*  & > :not(:nth-child(3n+3)) {
      margin-right: 1rem;
    } */

    & > * {
      margin-bottom: 1rem;
    }
  }

  &__buttons-wrapper {
    display: flex;
    justify-content: flex-end;

    & > :first-child {
      margin-right: .6rem;
    }
  }
}

.fileWrapFac {
  display: flex;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;