@import url("../src/legacy/vendor/fonts/inter.css");
@import url("./legacy/vendor/fonts/Monrope/Manrope.css");
@import url("./legacy/less/variables.less");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

h1 {
  cursor: default;
}

h2 {
  cursor: default;
}

.ant-table-cell {
  cursor: default;
}

h3 {
  cursor: default;
}

h4 {
  cursor: default;
}

p {
  cursor: default;
}

a {
  text-decoration: unset;
  color: unset;

  &:-webkit-any-link:active {
    color: unset;
  }

  &:-webkit-any-link:visited {
    color: unset;
  }
}


//mui override

.css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:after {
  display: none;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.6);
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;