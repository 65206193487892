.commentsTask {
  background: #ffffff;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  border-radius: 15px;
  padding: 30px;
  margin-top: 10px;
  h2 {
    display: flex;
    p {
      color: #c5c5c5;
      margin-left: 15px;
    }
  }
  .mainComment {
    position: relative;
    textarea {
      border: 2px solid #f5f6fa;
      box-sizing: border-box;
      border-radius: 15px;
      padding: 30px;
      height: 237px;
      width: 100%;
      outline: none;
      margin-bottom: 20px;
    }
    .mainComButs {
      position: absolute;
      display: flex;
      bottom: 23px;
      right: 0;
      .attacheBut {
        border: 2px solid #f0f0f0;
        box-sizing: border-box;
        border-radius: 15px;
        padding: 18px 24px;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        color: #2e2e3d;
        margin-bottom: 20px;
        cursor: pointer;
        transition: all 0.4s;
        margin-right: 22px;
        &:hover {
          transform: scale(1.05);
          transition: all 0.4s;
        }

        svg {
          margin-left: 12px;
        }
        &:last-of-type {
          border: none;
          background: #f5f6fa;
          color: #7f8191;
        }
      }
    }
  }
  .subComment {
    display: flex;
    //margin-top: 20px;
    div {
      max-width: 1312px;
    }
    img {
      height: 48px;
      min-width: 48px;
      max-width: 48px;
      border-radius: 12px;
      margin-right: 20px;
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      display: flex;
      align-items: center;
      padding-top: 7px;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: #7f8191;
        margin: 0 0 0 10px;

      }
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #7f8191;
      margin: 5px 0 0 0;
    }
    li {
      p {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        color: #2e2e3d;
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          margin-right: 8px;
        }
      }
    }
    .answerB {
      margin-top: 23px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      transition: all 0.4s;
      cursor: pointer;
      &:hover {
        transform: scale(1.03);
        transition: all 0.4s;
      }
      svg {
        margin-right: 8px;
      }
    }
    .attacheBut {
      border: 2px solid #f0f0f0;
      box-sizing: border-box;
      border-radius: 15px;
      padding: 18px 24px;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      cursor: pointer;
      transition: all 0.4s;
      margin-right: 22px;
      &:hover {
        transform: scale(1.05);
        transition: all 0.4s;
      }
      &:nth-of-type(3) {
        background: #7671DD;
        border: none;
        color: white;
        margin: 0;
      }
      svg {
        margin-left: 8px;
      }
    }
    .subButs {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
    }
  }
  .subField {
    width: 522px;
    textarea {
      height: 80px;
      width: 100%;
      border: 2px solid #7671dd;
      box-sizing: border-box;
      border-radius: 15px;
      outline: none;
      padding: 15px;
    }
  }
  .addedFile {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    cursor: pointer;
    margin: 11px 0 20px 0;
    svg {
      margin: 0 8px 0 0;
      &:last-of-type {
        margin: 0 0 0 10px;
      }
    }
    p {
      margin: 0 0 0 20px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #c5c5c5;
    }
  }
  .subWrap {
    max-height: 500px;
    transition: all 0.4s;
    display: flex;
    margin-top: 20px;
  }
  .subWrapHidden {
    height: 0;
    transition: all 0.4s;
    overflow: hidden;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;