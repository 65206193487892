.justInput {
  background: #ffffff;
  border: 2px solid #f5f6fa;
  box-sizing: border-box;
  border-radius: 15px;
  height: 64px;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #2e2e3d;
  padding-left: 15px;
  &::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #7f8191;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;