.moderCard {
  cursor: pointer;
  .applCardFoot {
    justify-content: flex-end;
  }
  .cardFootEqual {
    margin-top: -64px;
  }
  .disHidden {
    height: 0;
    transition: all 0.4s;
    overflow: hidden;
  }
  .discription {
    margin-top: 15px;
    max-height: 700px;
    transition: all 0.4s;
    overflow: hidden;
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      color: #7f8191;
      margin-bottom: 20px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #7f8191;
      margin-bottom: 20px;
    }
  }
  .moder {
    display: flex;
    align-items: center;
    transition: all 0.4s;
    &:hover {
      transform: scale(1.05);
      transition: all 0.4s;
    }
    svg {
      margin-left: 12px;
    }
  }
  .decline {
    border: 2px solid #f0f0f0;
    box-shadow: none;
  }
  .deadlines {
    margin: 10px 0 30px 0;
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #7f8191;
    }
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #2e2e3d;
    }
  }
  textarea {
    background: #ffffff;
    box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    width: 100%;
    height: 220px;
    margin-top: 30px;
    outline: none;
    padding: 15px;
    border: 2px solid #F5F6FA;
  }
  .btWrap {
    display: flex;
    position: absolute;
    bottom: 20px;
    right: 15px;
    button {
      padding: 12px 16px;
      font-family: Manrope;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #2e2e3d;
      background: #f5f6fa;
      border-radius: 15px;
      display: flex;
      align-items: center;
      transition: all 0.4s;
      cursor: pointer;
      margin-right: 10px;
      svg {
        margin-left: 12px;
      }
      &:hover {
        transform: scale(1.04);
        transition: all 0.4s;
      }
    }
  }
  .declineWrap {
    position: relative;
    overflow: hidden;
    height: 254px;
    transition: all 0.5s;
  }
  .declineHidden {
    height: 0;
    transition: all 0.5s;
  }
  .cardId {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #7f8191;
    }
  }
}

.tit {
  h2 {
    max-width: 81%;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;