.downloadFilteredDataButtonWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.summary {
  display: flex;
  justify-content: space-between;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;