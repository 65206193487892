.RefData {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  .getChekbox {
    display: flex;
    align-items: center;
    margin-bottom: 19px;
    #how-get {
      cursor: pointer;
      appearance: none;
      width: 20px;
      height: 20px;
      border: 2px solid #c5c5c5;
      box-sizing: border-box;
      border-radius: 10px;
      &:checked {
        border: 6px solid #7671DD;
      }
    }
    .refDataLabel {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      margin-left: 14px;
      color: #111418;
    }
  }
  .referencesMainFields{
    display: flex;
    .column {
      width: 50%;
    }
  }
}

.fieldsWrap {
  display: flex;
}

.getInfo {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #2e2e3d;
  margin: 20px 0 15px 0;
}


.refDataLabel {
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #111418;
  }
}

.refAmount {
  padding: 20px 26px 20px 15px;
  border: 2px solid #f5f6fa;
  box-sizing: border-box;
  border-radius: 15px;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  color: #2e2e3d;
  max-width: 107px;
}

.submitWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  .refSubmit {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    align-self: end;
    padding: 18px 24px;
    cursor: pointer;
    border-radius: 20px;
    background: #7671dd;
    color: white;
    box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
      0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
      0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
      0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
    height: 62px;
    width: fit-content;
    &:hover {
      transform: scale(1.05);
      transition: all 0.5s;
    }
    &:disabled {
      background: #7f8191;
      transform: scale(1);
      cursor: not-allowed;
    }
  }
   p {
    margin: 0 15px 0 0;
    color: #7671DD;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
   }
}

.ub-ml_8px {
  margin-left: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
}

.ub-mt_16px {
  margin-top: 16px;
  display: flex;
  align-items: center;
}


.amountWrap {
  .chooseField {
    width: 107px;
  }
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;