.studentJournalCard {
  background: #ffffff;
  box-shadow: 0px 12px 6px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  padding: 20px;
  width: 344px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    transition: all 0.5s;
  }
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #2e2e3d;
  }
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #7f8191;
    margin: 0;
  }
  .teacherWrap {
    background: #f5f6fa;
    border-radius: 12px;
    padding: 10px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    img {
      width: 39.5px;
      height: 48px;
      border-radius: 8px;
      object-fit: contain;
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      color: #2e2e3d;
      margin: 0;
    }
    p {
      margin: 0;
      font-size: 14px;
      font-weight: normal;
    }
    div {
      margin-left: 8.3px;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;