.WorkerIn {
  .ant-table-thead > tr > th {
    vertical-align: inherit;
    padding: 10px;
  }
  .ant-table-tbody > tr > td {
    cursor: pointer;
    text-align: center;
    padding: 0;
    vertical-align: inherit;
    &:hover {
      //border: 3px solid #DFE2EC;
      .edit {
        display: initial;
        &:hover {
          background-image: none;
        }
      } 
    }
    .edit {
      display: none;
    } 
  }
  .headGrop {
    display: flex;
    align-items: center;
    margin-left: 20px;
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #2e2e3d;
    }
  }
  .showResults {
    display: flex;
    align-items: center;
    cursor: pointer;
    p {
      margin: 0 0 0 11px;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #2e2e3d;
    }
  }
  .tableSet {
    display: flex;
    margin: 20px 0;
    justify-content: space-between;
    align-items: center;
  }
  .ant-table-tbody > tr > td:nth-child(2) {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #2e2e3d;
    padding: 0;
    vertical-align: inherit
  }

  .ant-table-tbody > tr > td:first-child {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #7f8191;
    padding: 0;
    vertical-align: middle;
    text-align: center;
  }
  .editBackground {
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: none;
    z-index: 2;
  }
  .backVisible {
    display: initial;
  }
  .currentCell {
    z-index: 100000000;
  }
  .fillCell {
    background: white;
    z-index: 43;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  overflow: visible;
}

.css-1k51tf5-MuiTooltip-tooltip {
  max-width: 500px;
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;