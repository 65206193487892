.quillContainer {
  border: 2px solid #f5f6fa;
  border-radius: 1rem;

  :global {

    .ql-toolbar,
    .ql-container {
      border: none;
    }

    .ql-toolbar {
      border-bottom: 2px solid #f5f6fa;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;