.wrapper {
  --text-color-light: #7f8190;

  display: flex;
  padding: .75rem 0 0 0;
}

.column {
  flex-basis: 50%;
}

.subtitle {
  color: var(--text-color-light);
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: .2rem;
}

.important {
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: .05rem;
  line-height: 1.1;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;