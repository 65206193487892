.journalsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, 344px);
  gap: 15px 20px;
  margin: 0;
  justify-content: center;
}

.K-ChooseSpec {
  background: #ffffff;
  border-radius: 15px;
  height: 62px;
  min-width: 256px;
  max-width: fit-content;
  margin-bottom: 50px;
  padding: 0 21px 0 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;