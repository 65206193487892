.ModeApply {
  .filterWrap {
    margin: 0 0 20px 0;
    //justify-content: space-between;
    input {
      margin-right: 30px;
      width: 32%;

      padding-right: 20px;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  .filterButts {
    display: flex;
    margin: 15px 0 40px 0;
    align-items: center;
    justify-content: space-between;
  }
  .chooseField {
    width: 100%;
    height: 100%;
  }
  .clear {
    margin-top: 39px;
  }
  .moderCards {
    margin-top: 40px;
  }
  .filterFields {
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 143px;
    transition: all 0.4s;
  }
  .dateWrap {
    width: 32%;
    position: relative;
    margin-left: 10px;
    input {
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      color: #7f8191;
    }
    svg {
      position: absolute;
      bottom: 29px;
      right: 15px;
    }
  }
  .react-calendar {
    bottom: -310px;
    right: 0;
  }
  .fieldSubtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #7f8191;
  }
  .chooseField {
    padding: 12px 30px 12px 13px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    border-radius: 15px;
    min-height: 64px;
    align-items: center;
    position: relative;
    cursor: pointer;
    background: white;
    margin: 0;

    &:hover {
      outline: 2px solid #c5c5c5;
    }

    h4 {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      color: #7f8191;
      margin: 0;
    }

    .fieldValue {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      color: #2e2e3d;
    }
  }
  .inp {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 100%;
    input {
      border: none;
      outline: none;
      padding: 0;
      background-color: inherit;
      height: fit-content;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      color: #2e2e3d;
      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 24px;
        color: #2e2e3d;
      }
    }
  }
  .dateWrap {
    margin: 0 10px 0 0;
    height: 100%;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;