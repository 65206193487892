.studentInfoRating {
  background: #ffffff;
  border: 2px solid #f5f6fa;
  box-sizing: border-box;
  box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  padding: 30px;
  margin: 30px 0 40px 0;
  display: flex;
  .commonInfoWrap {
    width: 100%;
    .fullName {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 36px;
      color: #2e2e3d;
    }
  }
  .personal {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #7f8191;
    margin-bottom: 20px;
  }
  .personalLists {
    display: flex;
    flex-wrap: wrap;
    border-bottom: #7f8191 solid 1px;
    padding: 10px;
    li {
      width: 33%;
      margin-bottom: 20px;
      h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #7f8191;
        margin: 0;
      }
      p {
        font-style: bold;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #2e2e3d;
        margin: 0;
      }
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;