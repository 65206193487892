.messagesCountWrapper {
  display: flex;
  justify-content: flex-end;
}

.messageBody {
  display: flex;
  flex-direction: column;
}

.messageNumber {
  align-self: flex-end;
}

.buttonsWrapper {
  display: flex;

  &_navigation {
    justify-content: flex-end;
    gap: .5rem;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;