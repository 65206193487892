.trash {
  background: rgb(235, 87, 87);
  width: 100%;
  height: 100%;
  display: inline-block;
  margin: 0 auto;
  cursor: pointer;

  position: relative;
  -webkit-border-bottom-right-radius: 13%;
  -webkit-border-bottom-left-radius: 13%;
  -moz-border-radius-bottomright: 13%;
  -moz-border-radius-bottomleft: 13%;
  border-bottom-right-radius: 13%;
  border-bottom-left-radius: 13%;

  span {
    position: absolute;
    height: 18%;
    background: rgb(235, 87, 87);
    top: -25%;
    left: -12%;
    /* right: -10px; */
    width: 125%;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform: rotate(0deg);
    transition: transform 250ms;
    transform-origin: 19% 100%;

    &::after {
      content: "";
      position: absolute;
      width: 35%;
      height: 55%;
      background: rgb(235, 87, 87);
      top: -80%;

      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      transform: rotate(0deg);
      transition: transform 250ms;
      transform-origin: 19% 100%;
      left: 31%;
    }
  }

  &:hover {
    span {
      transform: rotate(-45deg);
      transition: transform 250ms;
    }
  }

  i {
    position: relative;
    width: 10%;
    height: 63%;
    background: #fff;
    display: block;
    margin: 20% auto;
    border-radius: 5px;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background: #fff;
      position: absolute;
      left: -300%;
      border-radius: 5px;
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background: #fff;
      position: absolute;
      right: -300%;
      border-radius: 5px;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;