.allAvatarsWrap {
  display: flex;
}
.choosenAvatar {
  max-width: 140px;
  height: 140px;
  border-radius: 20px;
  margin-bottom: 10px;
  object-fit: cover;
}

.edit {
  &:hover {
    background-image: url("../../../../shared/assets/images/navigation/edit-avatar.svg");
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.4;
    cursor: "pointer";
  }
}

.allPhotos {
  max-width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-right: 5px;
  object-fit: cover;
  cursor: pointer;
  &:hover {
    opacity: 0.4;
    cursor: "pointer";
  }
}

.addPhoto {
  width: 60px;
  height: 60px;
  border: 2px dashed #f5f6fa;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.upload {
  position: relative;
  cursor: pointer;
  input {
    position: absolute;
    top: 0;
    bottom: 0;
    appearance: none;
    width: 60px;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;