.effective-contract-users-table {
  margin-bottom: 2.5rem;

  & a {
    color: #7671dd;
    text-decoration: underline;
  }

  & .ant-table {
    --text-color-light: #7f8190;
  }
  
  & .ant-table-thead > tr > th {
    border-bottom: 1px solid #f0f0f0;
    color: var(--text-color-light);
    word-break: unset;
    font-size: .8rem;
    padding: .8rem 1.2rem;
  }
  
  & .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    vertical-align: middle;
    padding: .7rem 1.2rem;
    color: var(--text-color-light);
    font-weight: 700;
    font-size: .8rem;
    word-break: unset;
  }
  
  & .editable-cell {
    position: relative;
  }

  & .ant-table-thead .ant-table-cell {
    background: unset;
  }

  & .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  & .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  & [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;