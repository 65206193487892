.taskPopup {
  position: fixed;
  top: 0;
  right: 0;
  display: none;
  width: 100%;
  height: 100%;
  align-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 200;
  overflow: hidden;

  .taskContainer {
    background: #ffffff;
    border-radius: 24px;
    padding: 40px;
    margin: auto;
    width: 704px;
    display: flex;
    flex-direction: column;
    position: relative;

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 36px;
      color: #2e2e3d;
      margin-bottom: 20px;
    }

    .formButton {
      background: #7671dd;
      box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
        0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
        0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
        0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
      border-radius: 15px;
      padding: 18px 0;
      color: white;
      cursor: pointer;
      transition: all 0.4s;

      &:hover {
        transform: scale(1.05);
        transition: all 0.4s;
      }
    }

    input {
      border: 2px solid #f5f6fa;
      box-sizing: border-box;
      border-radius: 15px;
      padding: 20px 15px;
      outline: none;
      width: 100%;
      margin-bottom: 20px;
      background: none;

      &::-webkit-calendar-picker-indicator {
        display: none;
        opacity: 0;
      }
    }

    .dash {
      border: 2px dashed #7671DD;
      background: #F5F7FF;
      box-sizing: border-box;
      border-radius: 15px;
      padding: 20px 15px;
      outline: none;
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #7671dd;
      margin-top: 20px;

      svg {
        margin-right: 23px;
      }
    }

    .noFull {
      svg {
        &:nth-of-type(2) {
          margin-left: 59%;
        }
      }
    }

    textarea {
      height: 142px;
      border: 2px solid #f5f6fa;
      box-sizing: border-box;
      border-radius: 15px;
      padding: 20px 15px;
      outline: none;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .full {
    align-items: center;

    p {
      margin: 0;
    }

    svg {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .dash {
    cursor: pointer;
  }

  .multiple {
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 0px 15px;
    outline: none;
    margin: 20px 10px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    color: #7f8191;
    min-height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 15px;

    span {
      display: flex;
    }

    input {
      border: none;
      padding: 0px 15px;
      margin: 0;
      min-height: 0;
    }

    svg {
      position: initial;
    }

    .specTag {
      display: flex;
      background: #f5f6fa;
      border-radius: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      margin-right: 10px;
      padding: 7px;

      svg {
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }

  .disabled {
    background: #7F8191;
    border-radius: 15px;
    padding: 18px 0;
    color: white;
  }

  .long {
    width: 100%;
    margin: 0;
  }

  .react-calendar {
    bottom: 95px;
  }

  .dateWrap {
    svg {
      bottom: 42px;
    }
  }
}

.taskPopAct {
  display: flex;
  position: fixed;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;