.header {
  display: flex;
  justify-content: space-between;
}

.tabButtonsWrapper {
  display: flex;
  gap: 1.25rem;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;