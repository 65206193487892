.appLink {
  transition: all 0.4s;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 10px;
  height: 72px;
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #2e2e3d;
  display: flex;
  align-items: center;
  padding-right: 15px;
  margin: 20px 0 0 0;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    transition: all 0.5s;
  }

  &:first-of-type {
    margin-right: 10px;
  }

  p {
    margin: 0;
  }

  img {
    margin: 0 23px 0 21px;
  }
}

.appContainer {
  display: flex;
  justify-content: center;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;