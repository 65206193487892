.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin: 0;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;