.payment {
  padding: 40px 84px 100px;
  display: flex;
  flex-direction: column;
  width: 1608px;
  min-height: 90vh;
  margin: auto;
  .contracts {
    display: flex;
  }
  .paymentTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 66px;
    color: #2e2e3d;
    margin: 40px 0 20px 0;
  }
}

@media all and (max-width: 1860px) {
  .payment {
  width: 1400px;
  }
  
}

@media all and (max-width: 1650px) {
  .payment {
  width: 1200px;
  }
  .scolarSideBar {
    margin-right: 50px;
   }
}

@media all and (max-width: 1440px) {
  .payment {
  width: 1000px;
  }
  .scolarSideBar {
    margin-right: 50px;
   }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;