.subtitle {
  font-size: 1.25rem;
  color: #7f8191;
  margin: 0;
}

.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;