.effective-contract {

  &__teacher-rating-table {

    .ant-table-cell {
      background-color: #fff;
    }

    td.ant-table-cell {

      span {
        display: inline-block;
        width: 100%;
      }
    }

    .ant-table-column-sorter {
      align-self: baseline;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;