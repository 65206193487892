.ratingTable {
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    color: #2e2e3d;
    margin: 60px 0 30px 0;
  }

  .ant-table-wrapper tr th {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #7f8191;
    vertical-align: middle;
    white-space: break-spaces;
  }

  .thWrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .tableTitle {
    max-width: 131px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #7f8191;
    margin: 0;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    cursor: pointer;
  }

  .ant-table-tbody>tr>td:first-child {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    vertical-align: middle;
  }

  .needGuid {
    white-space: nowrap;
    cursor: pointer;
    color: #7671dd;
  }

  .ant-table-tbody>tr>td {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #2e2e3d;
    text-align: initial;
  }

  .ant-table-wrapper {
    margin-bottom: 40px;
  }

  .pagination-simple {
    width: fit-content;
  }

  .paginationWrap {
    display: flex;

    .limitWrap {
      display: flex;
      align-items: center;
      width: 20%;
      white-space: nowrap;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #7f8191;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;