.wrapper {
  position: relative;

  &_additionalMargin {
    margin-bottom: 2rem;
  }
}

.inputWithoutBorder {
  border: none;
}

.openedList {
  border-radius: 0.6rem;
  box-shadow: 0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;
  display: flex;
  flex-direction: column;
  top: calc(100% + .5rem);
  width: 100%;
  overflow-y: hidden;
  padding-right: .5rem;
  background-color: #fff;
  z-index: 2;
  position: absolute;
  text-align: start;
}

[class~=d-scrollbar] {
  .openedList {
    ::-webkit-scrollbar {
      border: 1px solid transparent !important;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;