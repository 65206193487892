.detail-page-header {
  &__title {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.06rem;
    margin: 0;
  }

  &__subtitle {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.05rem;
    color: var(--text-color-light);
  }

  &__description-wrapper {
    order: 1;
  }

  &__cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;

    & > :not(:nth-child(3n + 3)) {
      margin-right: 1.2rem;
    }

    & > * {
      margin-bottom: 1.2rem;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #7671dd;
    &_courses {
      justify-content: space-between;
    }
  }

  &__title-wrapper {
    &_courses {
      display: flex;
      justify-content: space-between;
    }
  }

  &__price-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  &__price {
    font-weight: bold;
    font-size: 1.8rem;
    display: flex;
  }

  &__price-description {
    font-size: 1rem;

    &::before {
      content: "*";
      color: #ff5e5e;
      margin-right: 0.3rem;
    }
  }
}

.PopupTitle-K {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 10px;
}
.PopupParagraph-K {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #7F8191;
}

.learning-pathway-tab__attached-files-wrapper {
  cursor: pointer;
  .dash {
    margin-top: 0;
    svg {
      margin-left: 0;
    }
    p {
      cursor: pointer;
    }
  }
}

.trackEditWrap {
  display: flex;
  justify-content: space-between;
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;