.learning-pathway {
  --text-color-black: #2e2e3d;

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 66px;
    color: var(--text-color-black);
    margin: 40px 0 20px 0;
  }

  &__navigation-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .filter-drop-down {
      min-width: 0;
    }
  }

  &__buttons-wrapper :not(:last-child) {
    margin-right: .55rem;
  }
}

.exportWrap {
  display: flex;
  justify-content: flex-end;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;