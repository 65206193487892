.buttonsWrapper {
  display: flex;
}

.detailsCard {
  &_highlighted {
    border: 5px solid #F9B754;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;