.calendar-simple {
  align-items: center;
  background: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &_with-border {
    border: #f5f6fa 2px solid;
  }

  &__clickable-area {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding: .4rem 1rem;
    width: 100%;
  }

  &__dates-wrapper {
    display: flex;
    justify-content: flex-start;
    margin-right: 1rem;
    width: 100%;
    padding: 0 .2rem;
    letter-spacing: .02rem;
  }

  &__dates-dash {
    padding: 0 .5rem;
  }

  & .react-calendar {
    height: fit-content;
    top: 100%;
    width: fit-content;
    z-index: 1;

    &__month-view__days {
      background: #fff;
    }
  }

  & .display-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
  }

  & .position-static {
    position: static;
  }

  & .position-absolute {
    position: absolute;
  }

  & .dark-layer {
    background: rgba(0, 0, 0, 0.342);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  & .hidden-layer {
    display: none;
  }

  & .no-wrap {
    white-space: nowrap;
  }

  & .no-paddings {
    padding: 0;
  }

  & .no-marging {
    margin: 0;
  }

  & .light-stroke {
    stroke: #7F8190;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;