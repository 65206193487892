.noContracts {
  background: #ffffff;
  box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #7f8191;
  padding: 30px;
  margin-top: 20px;
  cursor: default;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;