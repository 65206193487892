.marker {
  transition: all 0.4s ease-in-out;
  border-radius: 20px;
}

.markerw {
  height: 62px;
  border-radius: 20px;
  background: #7671dd;
  box-shadow: 0px 27px 46px rgb(118 113 221 / 12%),
    0px 8.13971px 13.8677px rgb(118 113 221 / 8%),
    0px 3.38082px 5.75991px rgb(118 113 221 / 6%),
    0px 1.22278px 2.08325px rgb(118 113 221 / 4%);
}

.refBut {
  height: 62px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
}

.refActice {
  color: white;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;