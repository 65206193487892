.InnerPage {
  padding: 40px 85px;
  min-height: 90vh;
  width: 1608px;
  margin: auto;
}

@media all and (max-width: 1860px) {
  .InnerPage {
  width: 1400px;
  }
}

@media all and (max-width: 1650px) {
  .InnerPage {
  width: 1200px;
  }
}

@media all and (max-width: 1440px) {
  .InnerPage {
  width: 1000px;
  }
  
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;