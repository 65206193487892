.K-ResultsWrap {
  .ant-table-wrapper {
    background: #ffffff;
    border: 1px solid #e6e9f3;
    position: absolute;
    right: 0;
    top: 0;
    &:first-of-type {
      position: static;
    }
  }
  .ant-table-container table > thead > tr {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #2e2e3d;
    height: 72px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #2e2e3d;
    height: 72px;
  }
  .ant-table-tbody > tr > td:first-child { 
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #2e2e3d;
    height: 72px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;