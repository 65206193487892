.taskResult {
  background: #ffffff;
  box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  padding: 30px;
  .tag {
    border: 2px solid #f0f0f0;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 18px 24px;
    margin-right: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
  }
  input {
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    border-radius: 15px;
    height: 64px;
    width: 84%;
    position: relative;
    padding: 30px;
    outline: none;
    margin-right: 10px;
  }
  .addedFile {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    cursor: pointer;
    margin-bottom: 10px;
    svg {
      margin: 0 8px 0 0;
    }
    p {
      margin: 0 0 0 20px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #c5c5c5;
    }
  }
  .workerResult {
    display: flex;
    flex-direction: column;
    button {
      border: 2px solid #f0f0f0;
      box-sizing: border-box;
      border-radius: 15px;
      padding: 18px 24px;
      margin-right: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      transition: all 0.4s;
      cursor: pointer;
      &:hover {
        transform: scale(1.04);
        transition: all 0.4s;
      }
      &:last-of-type {
        background: #7671dd;
        box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
          0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
          0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
          0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
        color: white;
      }
    }
  }
  .endTask {
    margin-bottom: 20px;
    position: absolute;
    display: flex;
    bottom: 23px;
    right: 0;
    button {
      box-sizing: border-box;
      border-radius: 15px;
      padding: 18px 24px;
      margin-right: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      background: #7671dd;
        box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
          0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
          0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
          0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
        color: white;
        cursor: pointer;
        transition: all 0.4s;
        &:hover {
          transform: scale(1.04);
          transition: all 0.4s;
        }
    }
  }
  .attacheBut {
    border: 2px solid #f0f0f0;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 18px 24px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    cursor: pointer;
    transition: all 0.4s;
    margin-right: 22px;
    &:hover {
      transform: scale(1.05);
      transition: all 0.4s;
    }

    svg {
      margin-left: 12px;
    }
  }
  .mainComment {
    position: relative;
    textarea {
      border: 2px solid #f5f6fa;
      box-sizing: border-box;
      border-radius: 15px;
      padding: 30px;
      height: 237px;
      width: 100%;
      outline: none;
      margin-bottom: 20px;
    }
    .mainComButs {
      position: absolute;
      display: flex;
      bottom: 23px;
      right: 0;
      .attacheBut {
        border: 2px solid #f0f0f0;
        box-sizing: border-box;
        border-radius: 15px;
        padding: 18px 24px;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        color: #2e2e3d;
        margin-bottom: 20px;
        cursor: pointer;
        transition: all 0.4s;
        margin-right: 22px;
        &:hover {
          transform: scale(1.05);
          transition: all 0.4s;
        }

        svg {
          margin-left: 12px;
        }
        &:last-of-type {
          border: none;
          background: #f5f6fa;
          color: #7f8191;
        }
      }
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;