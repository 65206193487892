.pagination-simple {
  margin: auto;
  position: relative;
  
  &__background {
    background: #fff;
    height: 150%;
    width: 80%;
    position: absolute;
    top: -25%;
    left: 10%;
    border-radius: .6rem;
    z-index: 0;
  }

  & :first-child > li {
    padding: 0 .7rem;
    z-index: 2;

    &:first-child, 
    &:last-child {
      transform: scale(1.5);

      & > button:not(.Mui-disabled) {
        fill: #7671DD;

        & > svg {
          fill: inherit;
        }
      }
    }

    & .Mui-selected {
      background: #7671DD;
      color: #fff;
      border-radius: .6rem;
    }

    & > button {
      font-weight: bold;
      font-size: 1rem;
      transition: unset;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;