.header {
  display: flex;
  gap: 1.5rem;
}

.textInput {
  height: auto;
  width: 100%;
  min-width: 30%;
  max-width: 50%;
}

.filtersWrapper {
  display: flex;
  gap: 1.5rem;
  transition: max-height .5s ease-in-out;
  max-height: 100vh;

  &_collabsed {
    max-height: 0;
    overflow: hidden;
  }

  &>:nth-child(1n) {
    flex-grow: 1;
    width: 100%;
    height: 4rem;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;