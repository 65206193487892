.fileWrapES {
  border: 2px solid #f5f7ff;
  padding: 18px 22px;
  border-radius: 15px;
  margin-top: 10px;
  width: fit-content;
  cursor: pointer;
  transition: transform 0.4s;
  &:hover {
    transform: scale(1.05);
    transition: transform 0.4s;
  }
}

.dash {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #2e2e3d;
  display: flex;
  cursor: pointer;
  svg {
    margin-left: 13px;
  }
}

.full {
  margin-top: 28px;
  svg {
    margin-right: 8px;
  }
}

.gradeFieldTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #24252e;
  margin-top: 20px;
}

.popupGradeWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  .input-text {
    width: 77%;
  }
}

.gradeItem {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #2e2e3d;
  margin-left: 30px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 12px;
    cursor: pointer;
    transition: all 0.4s;
    &:hover {
      transform: scale(1.05);
      transition: all 0.4s;
    }
  }
}


.popup {
  .filter-drop-down__opened-list{
    max-height: 200px;
  }
.dateWrap {
  width: 49%;
  position: relative;
  margin-right: 10px;
  input {
    width: 100%;
  }
  svg {
    position: absolute;
    bottom: 23px;
    right: 15px;
  }
}

.chooseField {
  width: 49%;
  height: fit-content;
  h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    color: #7f8191;
  }
}
.multiple {
  border: 2px solid #f5f6fa;
  box-sizing: border-box;
  border-radius: 15px;
  //padding: 0px 15px;
  outline: none;
  margin: 20px 10px 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  color: #7f8191;
  min-height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //padding: 12px 15px;
  span {
    display: flex;
    flex-wrap: wrap;
  }
  input {
    border: none;
    padding: 0px 15px;
    margin: 0;
    min-height: 0;
    outline: none;
  }
  svg {
    position: initial;
  }
  .specTag {
    display: flex;
    background: #f5f6fa;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    margin-right: 10px;
    padding: 7px;
    margin-bottom: 5px;
    svg {
      margin-left: 12px;
      cursor: pointer;
    }
  }
}
.projPopup {
  max-height: 200px;
}
}


 .addTrackForm {
  .filter-drop-down_with-border {
    margin: 15px 0;
  }
  .specTag {
    display: flex;
    background: #f5f6fa;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    margin-right: 10px;
    padding: 7px;
    margin-bottom: 5px;
    width: fit-content;
    svg {
      margin-left: 12px;
      cursor: pointer;
    }
  }
  .filedArray {
    display: flex;
    flex-wrap: wrap;
  }
} 


@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;