.card {
  --description-text-main-color: #7f8191;
  --title-text-main-color: #2e2e3d;
  --header-active-background-color: #7671dd;
  --header-active-color: #fff;

  background-color: #f5f6fa;
  border-radius: 1.125rem;
  width: 21rem;
}

.header {
  padding: 1.25rem;
  border-radius: 1.125rem 1.125rem .625rem .625rem;
}

.header_active {
  background-color: var(--header-active-background-color);
}

.descriptionWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: .3rem;
  color: var(--description-text-main-color);
}

.descriptionText {
  font-style: normal;
  font-weight: 600;
  font-size: .8125rem;
}

.title {
  font-weight: 700;
  font-size: 1.25rem;
  letter-spacing: .035rem;
  line-height: 1.4;
  color: var(--title-text-main-color);
}

.header_active,
.descriptionWrapper_active,
.title_active {
  color: var(--header-active-color);
}

.footer {
  padding: 1.25rem;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;