.Notifications {
  .tableSet {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .N-filter {
      display: flex;
      align-items: center;
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 36px;
        color: #2e2e3d;
        margin-right: 40px;
      }
    }
    .lectures {
      background: #ffffff;
      border: 2px solid #f5f6fa;
      box-sizing: border-box;
      border-radius: 15px;
      width: 292px;
      height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 21px 20px 15px;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      color: #2e2e3d;
      position: relative;
      cursor: pointer;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;