.infoWrap {
  display: flex;
  .date {
    display: flex;
    flex-direction: column;
    width: 50%;
    h3 {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #7f8191;
    }
  }
}

.dorGrid {
  display: flex;
  flex-wrap: wrap;
  .wrap {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #7f8191;
  }
  .big {
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    display: flex;
    align-items: center;
  }
  .value {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: black;
    display: flex;
    align-items: center;
  }
  .debt {
    background: #ff5e5e;
    border-radius: 30px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }
  .plus {
    background: #00BA95;
    border-radius: 30px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }
}

.tarif {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  color: #2e2e3d;
  margin: 30px 0 10px 0;
  height: 36px;
}

.costil {
  .payButtons {
    justify-content: flex-start;
    button {
      margin-right: 10px;
    }
  }
}

.dormitoryTable {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
  color: #2e2e3d;
  margin: 40px 0 20px;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;