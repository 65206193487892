.wrapper {
  --text-color-black: #2e2e3d;

  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 40px 84px 100px;
  width: 1608px;
}

.newPageTitleMargin {
  margin: 2.5rem 0 1.25rem 0;
}

.h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 2.63rem;
  color: var(--text-color-black);
}

.h3 {
  color: var(--text-color-black);
  font-size: 1.7rem;
  font-weight: bold;
  letter-spacing: .005rem;
}

.h4 {
  color: var(--text-color-black);
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: .005rem;
}

.h5 {
  color: var(--text-color-black);
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: .005rem;
}

@media all and (max-width: 1860px) {
  .wrapper {
  width: 1400px;
  }
}

@media all and (max-width: 1650px) {
  .wrapper {
  width: 1200px;
  }
}

@media all and (max-width: 1440px) {
  .wrapper {
  width: 1000px;
  }
  
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;