.innerTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
  color: #2e2e3d;
  margin-top: 40px;
  span {
    color: #7671dd;
  }
}

.tableSet {
  display: flex;
  margin: 20px 0;
  .lectures {
    background: #ffffff;
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    border-radius: 15px;
    width: 292px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 21px 20px 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    color: #2e2e3d;
    position: relative;
    cursor: pointer;
  }
}

.studentInner {
  
  .K-LecturePop {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-content: center;
    background: rgba(0, 0, 0, 0.6);
    z-index: 200;
    .LectureContainer {
      background: #ffffff;
      border-radius: 24px;
      padding: 40px;
      margin: auto;
      position: relative;
      max-width: 704px;
      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 36px;
        color: #2e2e3d;
      }
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #2e2e3d;
        margin-bottom: 10px;
        span {
          color: #7f8191;
        }
      }
      .dowWrap {
        display: flex;
        flex-direction: column;
      }
    }
    .close {
      position: absolute;
      background: #ffffff;
      box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
      border-radius: 10px;
      width: 64px;
      height: 64px;
      top: 0;
      right: -70px;
      cursor: pointer;
      font-size: 23px;
    font-weight: bold;
    }
    .download {
      background: #f5f6fa;
      border-radius: 10px;
      padding: 13px 10px 13px 15px;
      width: fit-content;
      display: flex;
      img {
        margin-right: 10px;
      }
      .dz {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #2e2e3d;
        margin-bottom: 2px;
      }
      .weight {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #7f8191;
        margin: 0;
      }
    }
  }

  .ant-table-wrapper {
    background: #ffffff;
    border: 1px solid #e6e9f3;
    border-radius: 10px;
  }
  .ant-table-wrapper tr th {
    white-space: nowrap;
    background: #ffffff;
    outline: 1px solid #e6e9f3;
  }
  .ant-table-tbody > tr > td {
    outline: 1px solid #e6e9f3;
    padding-top: 15px;
    text-align: left;
    &:first-of-type {
      text-align: inherit;
    }
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #7f8191;
    vertical-align: middle;
  }
  .ant-table-tbody > tr > td {
    &:first-child {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #2e2e3d;
      padding-top: 15px;
    }
  }
  /* .ant-table-cell {
    height: 60px;
  }   */
}

.infoTitle {
  display: flex;
  align-items: baseline;
  justify-content: center;
  p {
    width: 108px;
    overflow: hidden;
    white-space: break-spaces;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
  }
  img {
    margin-right: 11.24px;
  }
  div {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #7f8191;
    margin-left: 10px;
  }
}

.__react_component_tooltip.show {
  opacity: 0.9;
  margin-top: 0;
  margin-left: 0;
  visibility: visible;
  width: 210px;
  text-align: center;
  border-radius: 8px;
  background: #43444d;
}

.K-LecturePop-Close {
  display: none;
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;