.input-select {
  --texr-color-grey: #7f8190;

  align-items: center;
  background: #fff;
  border: 2px solid #f5f6fa;
  border-radius: 1rem;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  padding: 1rem 1.3rem 1rem .75rem;
  position: relative;
  min-width: fit-content;
  width: 100%;

  &> {
    text-align: start;
  }

  &_with-value {
    padding: .4rem 1.3rem .7rem .75rem;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    max-width: 95%;
  }

  &__label-hint {
    color: var(--texr-color-grey);
    font-size: 1.05rem;
    letter-spacing: .01rem;

    &_lifted {
      font-size: .9rem;
      letter-spacing: .005rem;
      margin-bottom: .1rem;
    }
  }

  &__selected-value {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .035rem;
    width: 100%;
    line-height: 1.5;
  }

  &__opened-list {
    background: #fff;
    border-radius: .6rem;
    box-shadow: 0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;
    display: flex;
    flex-direction: column;
    left: 0;
    padding: 1.25rem 0;
    position: absolute;
    top: calc(100% + .5rem);
    width: 100%;
    z-index: 1;

    &_with-fixed-position {
      left: auto;
      position: fixed;
      top: unset;
      width: auto;
    }

    &_with-absolute-position {
      left: auto;
      position: absolute;
      top: unset;
      width: auto;
    }
  }

  &__option-choose {
    font-size: .94rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .03em;
    padding: .6rem 1.25rem;

    &:hover {
      background: #f5f6fa;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;