.card-discipline-detail {
  --color-accent: #7671dd;
  --text-color-black: #2e2e3d;
  --text-color-grey: #7f8190;
  --color-light-grey: #f5f6fa;
  --card-hover-shadow: 0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;

  background: var(--color-light-grey);
  border-radius: 1rem;
  border: 2px solid var(--color-light-grey);
  color: var(--text-color-black);
  font-size: 1.1rem;
  font-weight: bold;
  padding: 1.8rem 1.7rem 1.85rem;
  width: 28rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;

  &:hover {
    border: 2px solid var(--color-accent);
    color: unset;

    .card-discipline-detail__name {
      color: var(--color-accent);
    }
  }

  &__title-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__name {
    font-size: 1.2rem;
    letter-spacing: .07rem;
    margin-bottom: .4rem;
    max-width: 90%;
    line-height: 1.45;
  }

  &__visible-icon {
    color: var(--text-color-grey);

    &_active {
      color: var(--color-accent);
    }

    &:hover {
      color: var(--color-accent);
      cursor: pointer;
    }
  }

  &__type {
    color: var(--text-color-grey);
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: .07rem;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;