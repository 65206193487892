.projPopup {
  background: #ffffff;
  border: 2px solid #f5f6fa;
  box-sizing: border-box;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  border-radius: 20px;
  width: fit-content;
  min-width: 100px;
  min-height: 100px;
  position: absolute;
  left: 0;
  top: 80px;
  padding: 10px 0 20px 0;
  z-index: 6;
  max-height: 500px;
  overflow-y: scroll;

  .projPopupItem {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    padding: 10px 20px;
    white-space: inherit;
    cursor: pointer;

    &:hover {
      background: #f5f6fa;
    }
  }

  .consultant {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .mail {
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      color: #7f8191;
    }

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #2e2e3d;
      width: fit-content;
    }
  }

  .persons {
    display: flex;
    /*  p {
      margin-left: 0;
    } */
  }

  &::-webkit-scrollbar {
    width: 0;
  }
}

.hidden {
  display: none;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;