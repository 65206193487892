

  .slick-slider {
    display: flex;
    align-items: center;
  }

  .slick-slider,
  .slick-list,
  .slick-track {
    height: inherit;
    width: 100%;
  }

  .Arrow {
    align-items: center;
    background: white;
    border-radius: 50%;
    display: flex;
    height: 2.5rem;
    justify-content: center;
    position: absolute;
    width: 2.5rem;
    z-index: 1;

    &:hover {
      transform: scale(1.1);
    }
  }

  .Arrow-left {
    left: 5%;
    top: 45%;
  }

  .Arrow-right {
    right: 5%;
    top: 45%;
  }

  .To-Left img {
    transform: scale(-1, -1);
  }

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;