.wrapper {
  padding-left: 18.75rem;
  display: flex;
}

.pagination {
  z-index: 0;
}

.limitWrapper {
  color: #7F8190;
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.lable {
  display: inline-block;
  width: fit-content;
  white-space: nowrap;
}

.selectInput {
  width: 8.75rem;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;