.applyResponse {
  background: #f5f6fa;
  box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  .imageWrap {
    display: flex;
    align-items: center;

    img {
      border-radius: 10px;
      width: 100px;
      height: 100px;
      margin-right: 30px;
    }

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #2E2E3D;
      margin: 0;
    }

    .age {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #7F8191;
      margin: 0;
    }

    .spec {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #7F8191;
      margin: 0;

      span {
        color: #2E2E3D;
      }
    }
  }

  .buttonsWrap {
    margin-top: 12px;
    display: flex;
    button {
      padding: 18px 24px;
      background: #FFFFFF;
      border-radius: 15px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      margin-right: 10px;
      color: #2E2E3D;
      cursor: pointer;
      transition: all 0.4s;
      &:hover {
        transform: scale(1.05);
        transition: all 0.4s;
      }
      &:nth-of-type(2) {
        background: #7671DD;
        color: white;
      }
    }
  }
  .panel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .appr {
    background: #64d770;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: white;
    width: fit-content;
    padding: 8px 10px;
    height: fit-content;
  }
  .rej {
    background: #FF5E5E;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
    width: fit-content;
    padding: 8px 10px;
  }
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;