.notifyCard {
  background: #ffffff;
  box-shadow: 0px 14px 19px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 18px;
  display: flex;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    transform: scale(1.02);
    transition: all 0.4s;
  }
  div {
    margin-left: 18px;
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      margin: 0;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      display: flex;
      color: #959595;
      margin: 0;
    }
  }
}

.newNotif {
  padding: 0 10px;
  border-radius: 5px;
  background-color: #7671DD;
  width: fit-content;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;