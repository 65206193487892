.homework {
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #2e2e3d;
  }
  .LectureContainer {
    width: 704px;
    display: flex;
    flex-direction: column;
  }
  .homeButons {
    display: flex;
    border-bottom: 2px solid #f5f6fa;
    justify-content: space-around;
    button {
      cursor: pointer;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      margin: 20px 0 10px 0;
    }
    .active {
      border-bottom: 2px solid #7671dd;
    }
  }
  .submit {
    background: #7671dd;
    box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
      0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
      0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
      0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
    border-radius: 20px;
    padding: 18px 24px;
    color: white;
    margin-top: 20px;
    align-self: flex-end;
  }
  .editLecture {
    margin-top: 20px;
    input {
      outline: none;
      border: none;
      width: 580px;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #2e2e3d;
    }
  }
  .discription {
    margin-top: 20px;
    outline: none;
    background: #ffffff;
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    border-radius: 15px;
    height: 243px;
    width: 100%;
    display: flex;
  }
  .clipWrap {
    background: #f5f6fa;
    border-radius: 10px;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;