.discipline-detail-card {

  & > :not(:last-child) {
    margin-bottom: 1.25rem;
  }

  &__header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: .6rem 0 0 0;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: .12rem;
  }

  &__list {
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: .05rem;
    padding: 0;
    list-style-position: inside;
    line-height: 2;
  }

  &__description {
    font-size: 1rem;
  }

  &__description-title {
    margin-bottom: 1.2rem;
    font-weight: bold;
    letter-spacing: .08rem;
  }

  &__description-subtitle {
    color: var(--text-color-light);
    line-height: 1.6;
    margin-bottom: .2rem;
    margin-top: 15px;
  }

  &__description-text {
    margin-bottom: .7rem;

    &_important {
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 1.1;
      letter-spacing: .05rem;
    }
  }

  &__additional-info-wrapper {
    padding: .75rem 0 0 0;
    display: flex;
  }

  &__additional-info {
    flex-basis: 50%;
  }

  &__description-subject-content-wrapper {
    color: var(--color-accent);
    display: flex;
    width: fit-content;
    cursor: pointer;
  }

  &__description-subject-content {
    font-weight: bold;
    font-size: 1.05rem;
    margin-right: 0.8rem;
  }

  &__teacher,
  &__statistics,
  &__students-distribution {
    font-size: 1rem;
  }

  &__teacher-main-info {
    display: flex;
    margin-bottom: .7rem;
  }

  &__teacher-main-info-photo {
    width: 4rem;
    height: 4rem;
    border-radius: .8rem;
    background: var(--text-color-light);
    margin-right: .5rem;

    & > svg {
      width: inherit;
      height: inherit;
      color: var(--text-color-black);
    }
  }

  &__teacher-main-info-name {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    &:first-of-type {
      margin-right: 13%;
    }
  }

  &__students-distribution-buttons-wrapper {

    & > :first-child {
      margin-right: .6rem;
    }
  }
}

.DisciplineResultsDetailPopup{
  overflow: scroll;
}

.teachersEditWrap{
  display: flex;
  flex-wrap: wrap;
  .input-text {
    margin-right: 2%;
    margin-bottom: 10px;
    &:nth-of-type(3){
      margin-right: 0;
    }
    &:nth-of-type(8){
      margin-right: 0;
    }
  }
}

.additionalInfoWrap{
  display: flex;
  &:first-child{
    margin-right: 13%;
  }
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;