.paper-container {
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0px 12.521552085876465px 6.636422634124756px 0px #00000004;
  margin-bottom: .5rem;
  padding: 1.6rem 1.8rem;

  &_sub-paper {
    box-shadow: unset;
    border: 2px solid #f5f6fa;
    border-radius: 1.25rem;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;