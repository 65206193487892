.tableTasks {
  margin-top: 20px;
  .ant-table-wrapper tr th {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #7f8191;
  }
  .ant-table-tbody > tr > td:first-child {
    &:hover {
      color: #7671DD;
      cursor: pointer;
    }
  }
  .ant-table-tbody > tr > td:first-child {
    font-weight: normal;
    line-height: 26px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;