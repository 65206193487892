.effective-contract-user-list {

  &__inputs-inner {
    width: 100%;
  }

  &__inputs-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &>* {
      margin-bottom: 1rem;
    }
  }

  &__filters-inner {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
  }

  &__filters-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 56.8rem;

    &>* {
      margin-bottom: 1rem;
    }
  }

}


.effective-contract {
  .pagination-simple {
    transform: translateX(20%);
  }

  .paginationWrap {
    display: flex;

    .limitWrap {
      display: flex;
      align-items: center;
      width: 20%;
      white-space: nowrap;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: .85rem;
      line-height: 1rem;
      color: #7f8191;

      .chooseField {
        background-color: #fff;
        padding: 1rem;
        margin: 0 .6rem 0;
        width: 100%;
        border-radius: 1rem;
      }
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;