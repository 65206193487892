.link-button {
  --color-accent: #7671dd;

  align-items: flex-end;
  color: var(--color-accent);
  cursor: pointer;
  display: flex;
  width: fit-content;

  &__inner-text {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: .03rem;
    line-height: normal;
    margin-right: .6rem;

    &_error {
      color: red;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;