.react-calendar .off {
    width: 0;
    height: 0;
}

.ScheduleCalendar .react-calendar__tile {
    border: 1px solid #f5f6fa;
}

.ScheduleCalendar .react-calendar__navigation__arrow {
    width: 32px;
    height: 32px;
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    font-weight: 600;
    font-size: 22px;

    background: #7671dd;
    border-radius: 10px;
}

.ScheduleCalendar .react-calendar__tile--active {
    background: #7671dd;
    color: white;
}

.ScheduleCalendar .react-calendar__tile--active {
    background-color: #7671dd;
}

.ScheduleCalendar .react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
    display: none;
}

.ScheduleCalendar .react-calendar__navigation__next2-button {
    display: none;
}

.ScheduleCalendar .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0;
}

.ScheduleCalendar .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}

.ScheduleCalendar .react-calendar__navigation__label {
    color: #7671dd;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
}

.react-calendar {
    position: absolute;
    bottom: -305px;

    width: 306px;
    min-height: 300px;

    background: #ffffff;
    /* line-grey */

    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    /* card-shadow */

    box-shadow: 0px 12px 6px rgba(0, 0, 0, 0.04);
    border-radius: 15px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;

    padding: 10px;

    transition: 0.3s all ease;
}
.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    // border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
    align-items: center;
}
.react-calendar__navigation button {
    // min-width: 44px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    // background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    // text-transform: uppercase;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: grey;
}
.react-calendar__month-view__weekdays abbr {
    text-decoration: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    border-bottom: 0;
    cursor: pointer;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    font-weight: 700;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
    // color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    width: 40px;
    height: 36px;
    text-align: center;
    margin: 1px;
    border: 1px solid #f5f6fa;
}
.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}
.ScheduleCalendar .react-calendar__tile:enabled:hover,
.ScheduleCalendar .react-calendar__tile:enabled:focus {
    background-color: #7671dd;
    color: white;
}
.react-calendar__tile--now {
    background: none;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #a5a1f0;
}
.react-calendar__tile--hasActive {
    background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}
.react-calendar__tile--active {
    background: #7671dd;
    color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #8882fd;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

.react-calendar__navigation__label {
    background: none;
}

.react-calendar__navigation__label:hover {
    transform: scale(1.05);
}

.react-calendar__navigation__arrow {
    width: 32px;
    height: 32px;
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    font-weight: 600;
    font-size: 22px;

    background: #7671dd;
    border-radius: 10px;
}

.react-calendar__navigation__label {
    color: #7671dd;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;