.addStudentWrap {
  display: flex;
  flex-direction: column;
  .searchWrap {
    padding: 18px;
    background: #f5f6fa;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin: 7px 0 20px 0;
    svg {
      margin-right: 21px;
    }
    input {
      border: none;
      outline: none;
      background: inherit;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      color: #2e2e3d;
      width: 100%;
    }
  }
}

.addStudentsScroll::-webkit-scrollbar {
  width: 0;
}

.addStudentsScroll {
  height: 500px;
  overflow: scroll;
}

@media all and (max-width: 1860px) {
  .addStudentsScroll {
    height: 400px;
  }
}

@media all and (max-width: 1650px) {
  .addStudentsScroll {
    height: 400px;
  }
}

@media all and (max-width: 1440px) {
  .addStudentsScroll {
    height: 300pxpx;
  }
  
}


.addStudentTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;