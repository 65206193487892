.wrapper {
  display: flex;
  align-items: flex-start;

  &>:first-child {
    margin-right: .5rem;
  }
}


.editIcon {
  color: #F9B754;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
}


.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &>:last-child {
    visibility: hidden;
  }
}


.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;