.cardAddItem {
  --text-color-black: #2e2e3d;
  --accent-color: #7671dd;
  --active-bg-color: #f5f7ff;

  align-items: center;
  border-radius: 1.1rem;
  color: var(--text-color-black);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 1.05rem;
  font-weight: bold;
  justify-content: center;
  margin: 0 1.2rem 1.3rem 0;
  padding: .9rem 1.2rem .7rem 1.2rem;
  width: 26.25rem;
  background-image: url("data:image/svg+xml;utf8, <svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' rx='20' style='fill: transparent; stroke: %237671dd; stroke-width: 4; stroke-dasharray: 10 10'/></svg>");


  &:hover {
    background-color: var(--active-bg-color);
  }
}


.iconWrapper {
  color: var(--accent-color);
  height: 2rem;
  margin-bottom: .7rem;
  width: 2rem;
}


.icon {
  height: 100%;
  width: 100%;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;