.projects_introduction {
  .accordionTitle {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .EmployeApplicationCardHeader {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #f5f6fa;
  }
  .titleWrap {
    border: none;
    margin-bottom: 0;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;