.inputLable {
  display: flex;
  flex-direction: column;
  background: yellow;
  margin-bottom: 20px;
}

.infoItemWrap {
  background: rgba(245, 246, 250, 1);
  border: 2px solid #f5f6fa;
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 12px 15px 8px;
  .infoItemTitle {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #a4a6b3;
    margin: 0;
  }
  .infoItemValue {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    color: #7F8191;
    margin: 0;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;