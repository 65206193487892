.disabled {
  background: #7f8191;
}

.taskPopAct {
  .close {
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 9px 14px rgb(0 0 0 / 3%);
    border-radius: 10px;
    width: 64px;
    height: 64px;
    top: 0;
    right: -70px;
    cursor: pointer;
    font-size: 23px;
    font-weight: bold;
  }
}



@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;