.sucess {
  background: #ffffff;
  border-radius: 24px;
  padding: 40px;
  width: 542px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 36px;
    color: #2e2e3d;
    margin-top: 50px;
  }
  .info {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    margin-bottom: 20px;
  }
  .button {
    width: 100%;
    background: #7671dd;
    height: 62px;
    color: white;
    border-radius: 20px;
  }
  span {
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
  }
}

.successHidden {
  display: none;
}

.sucessWrap {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 10000;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;