.numberSms {
  width: 8%;
    padding: 1.2rem;
    outline: none;
    border: none;
    border-radius: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;