.successHidden {
  .close {
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    width: 64px;
    height: 64px;
    top: 0;
    right: -70px;
    cursor: pointer;
    font-size: 23px;
  font-weight: bold;
  }
  
}

.qr {
  .activeBut {
    transition: all 0.4s;
    border-radius: 20px;
    margin-right: 10px;
    padding: 18px 24px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    background: #7671dd;
    box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
      0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
      0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
      0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
    color: white;
    &:hover {
      opacity: 0.8;
      transition: all 0.4s;
  }
}
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;