.mfc {
  display: flex;
  flex-direction: column;
  padding: 40px 85px 100px;
  min-height: 80%;
  width: 1608px;
  margin: auto;
  ul {
    margin: 0;
  }
}

.mfcTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 66px;
  color: #2e2e3d;
  margin: 0;
  padding: 40px 0 20px 0;
}

.statusButton {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  background: white;
  padding: 18px 24px;
  margin-right: 10px;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
    0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
    0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
    0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
  &:hover {
    outline: 2px solid #7671dd;
  }
  &:last-of-type {
    margin: 0;
  }
}

.buttonActive {
  background: #7671dd;
  color: white;
  outline: none;
  &:hover{
    outline: none;
  }
}

.battonsWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}


@media all and (max-width: 1860px) {
  .mfc {
  width: 1400px;
  }
}

@media all and (max-width: 1650px) {
  .mfc {
  width: 1200px;
  }
}

@media all and (max-width: 1440px) {
  .mfc {
  width: 1000px;
  }
  
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;