.titleWrap {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 2px solid #f5f6fa;
  margin-bottom: 20px;
  .status {
    display: flex;
  }
  p {
    margin: 0;
    display: flex;
    align-items: center;
  }
}

.reference {
  background: #ffffff;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  border-radius: 25px;
  padding: 34px 30px 30px;
  margin-bottom: 10px;
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
  }
  h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #7f8191;
    margin: 0;
  }
  button {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 24px;
  }
  .inactive {
    color: #F5F6FA;
  }
}

.subtitleRef {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
}

.status {
  .stuBut {
    border-radius: 10px;
    padding: 6px 8px;
    margin: 0px 10px 0 30px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
  .download {
    color: #7671DD;
    margin: 0 0 0 13px;
    cursor: pointer;
  }
  button{
    cursor: pointer;
    &:disabled{
      cursor: default;
    }
  } 
}

.count {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #7f8191;
  margin: 0;
}

.countSpan {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  color: #2e2e3d;
}

.rejected {
  background: #ff4d4d;
  color: white;
}

.new {
  background: #f5f6fa;
}

.process {
  background: #ff984e;
  color: white;
}

.ready {
  background: #64d770;
  color: white;
}

.download {
  color: #7671DD;
  margin: 0 0 0 13px;
  cursor: pointer;
}

.resendMfc{
  border-radius: 10px;
  cursor: pointer;
  padding: 5px 10px;
  transition: transform 0.4s;
  margin-left: 10px;
  &:hover{
    transform: scale(1.05);
    transition: transform 0.4s;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;