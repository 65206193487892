.internetBarToggleWrap {
  input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 64px;
    height: 32px;
    background: grey;
    display: block;
    border-radius: 50px;
    position: relative;
    margin-right: 20px;
  }

  label:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 24px;
    transition: 0.3s;
  }

  input:checked + label {
    background: #7671DD;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 59px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;