.scoresWrapper {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.scoresCheckbox {
  padding-left: 1rem !important;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;