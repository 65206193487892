.footerInfo {
  display: flex;
  flex-direction: column;
  h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #2e2e3d;
    margin: 0 0 22px 0;
  }
  a {
    transition: all 0.4s;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #2e2e3d;
    margin-bottom: 20px;
    &:last-of-type {
      margin: 0;
    }
    &:hover {
      color: #7671DD;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;