.recordBook {
  padding: 40px 85px 100px;
  display: flex;
  flex-direction: column;
  width: 1608px;
  margin: auto;
 /*  .ant-table-cell {
    border-bottom: 1px solid #F3F4F6;
  } */
  .ant-table-tbody > tr > td { 
    border-bottom: 1px solid #F3F4F6;
  }
  .ant-col {
    position: static;
    margin-bottom: 12px;
}
}

.ant-btn {
  box-shadow: none;
  background: none;;
}

.recCardsWrap {
  display: flex;
}

@media all and (max-width: 1860px) {
  .recordBook {
  width: 1400px;
  }
  .ant-table-cell:nth-child(6) {
    word-break: normal;
}
.ant-table-cell:nth-child(7) {
  word-break: normal;
}
}

@media all and (max-width: 1650px) {
  .recordBook {
  width: 1200px;
  }
  .ant-table-cell:nth-child(6) {
    word-break: normal;
}
.ant-table-cell:nth-child(7) {
  word-break: normal;
}
}

@media all and (max-width: 1440px) {
  .recordBook {
  width: 1000px;
  }
  .ant-table-cell:nth-child(6) {
    word-break: normal;
}
.ant-table-cell:nth-child(7) {
  word-break: normal;
}
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;