.getRefBut {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  background: #ffffff;
  border-radius: 20px;
  width: fit-content;
  padding: 18px 24px 18px 28px;
  margin-top: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    transition: all 0.5s;
  }
}

.getRefContent {
  background: #ffffff;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  border-radius: 15px;
  padding: 30px;
}

.getRefSubtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
  color: #2e2e3d;
  margin-bottom: 20px;
}

.chooseField {
  padding: 12px 30px 12px 13px;
  display: flex;
  justify-content: space-between;
  width: 50%;
  border: 2px solid #f5f6fa;
  box-sizing: border-box;
  border-radius: 15px;
  min-height: 64px;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-right: 10px;

  h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #a4a6b3;
    margin: 0;
  }
  .fieldValue {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    color: #2e2e3d;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;