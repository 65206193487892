.Navigation {
  margin-top: 6.25rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 31%);
  justify-content: space-between;
  gap: 1.875rem;

  @media screen and (max-width: 1700px) {
    margin-top: 5rem;
  }

  @media screen and (max-width: 1440px) {
    margin-top: 3.5rem;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(auto-fill, 47%);
  }

  .RecordLinks {
    margin-left: 20%;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    li {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 28px;
      color: #2e2e3d;
      transition: all 0.4s;

      &:hover {
        color: #7671DD;
        transition: all 0.4s;
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }

  .cardNot {
    transform: scale(1);

    &:hover {
      transform: scale(1);
    }
  }
}

.Navigation .Card {
  width: 100%;
  height: 230px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 1.875rem;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  border-radius: 15px;
  transition: 0.3s all ease;

  &:last-of-type {
    &:hover {
      transform: scale(1);
    }
  }
}

.Navigation .Card:hover {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.025);
}

.Navigation .Card .C-h2 {
  padding: 10px 0 8px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
}

.Navigation .Card .Arrow {
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: 30px;
  right: 30px;

  border-radius: 50%;
  background: #f5f6fa;

  transition: 0.3s all ease;
}

.Navigation {
  .Card {
    &:hover {
      .Arrow {
        background: #7671dd;
      }

      .subtitle {
        color: #2e2e3d;
      }
    }
  }
}

.Navigation .Card:hover {
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.025);

  transition: 0.3s all ease;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;