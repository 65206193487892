.taskCard {
  background: #ffffff;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  border-radius: 15px;
  padding: 30px;
  margin: 20px 0 10px 0;
  .status {
    background: #f5f6fa;
    border-radius: 10px;
    padding: 8px 10px;
    width: fit-content;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    margin-bottom: 10px;
  }
  .mainInfo {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    //border-bottom: 2px solid #f5f6fa;
    margin-bottom: 20px;
    .infoItem {
      background: #f5f6fa;
      border-radius: 12px;
      padding: 15px;
      min-width: 32.5%;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        color: #7f8191;
        margin: 0;
      }
      p {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #2e2e3d;
        margin: 0;
      }
    }
  }
  .teacher {
    display: flex;
    background: #f5f6fa;
    border-radius: 12px;
    align-items: center;
    padding: 15px;
    margin-bottom: 15px;
    min-width: 32.5%;
    img {
      border-radius: 14px;
      width: 64px;
      height: 64px;
    }
    div {
      display: flex;
      flex-direction: column;
      margin-left: 13px;
    }
    .subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #7f8191;
      margin: 0;
    }
    .value {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #2e2e3d;
      font-style: normal;
      margin: 0 0 10px 0;
    }
  }
  .taskText {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    margin-bottom: 20px;
  }
  .attacheBut {
    border: 2px solid #f0f0f0;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 18px 24px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all 0.4s;
    width: fit-content;
    &:hover {
      transform: scale(1.05);
      transition: all 0.4s;
    }

    svg {
      margin-left: 12px;
    }
  }
  .addedFile {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    cursor: pointer;
    margin-bottom: 20px;
    svg {
      margin: 0 8px 0 0;
      &:last-of-type {
        margin: 0 0 0 10px;
      }
    }
    p {
      margin: 0 0 0 20px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #c5c5c5;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;