.portfolio {
  .cardList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1.8rem;
  }
}

.css-17o5nyn {
  justify-content: space-between;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;