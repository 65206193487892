.mainFinInfo {
  background: #ffffff;
  border-radius: 25px;
  border: 2px solid #f0f0f0;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 260px;
  justify-content: space-between;
  padding: 40px;
  width: 440px;

  .penalti {
    color: #ff5e5e;
    display: flex;
    font-size: 15px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
    margin: 0;
  }

  .warn {
    background: #ff5e5e;
    border-radius: 30px;
    color: #fff;
    display: flex;
    height: 24px;
    justify-content: center;
    margin-left: 8px;
    width: 24px;
  }

  .orangeBack {
    background-color: #fc8d3b;
  }

  .orange {
    color: #fc8d3b;
  }

  .infoGeneral {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;

    .plus {
      color: #00ba95;
      display: flex;
      font-size: 15px;
      font-style: normal;
      font-weight: bold;
      line-height: 24px;
      margin: 0;
    }

    .oreal {
      align-items: center;
      background: #eb5757;
      border-radius: 34px;
      display: flex;
      height: 64px;
      justify-content: center;
      width: 64px;
    }

    .orealGreen {
      background: #2cc28c;
    }

    .orealOrange {
      background: #fc8d3b;
    }

    .orealGrey {
      background: #F5F6FA;
    }
  }

  h2 {
    color: #2e2e3d;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 28px;
  }

  .jopa {

    h3 {
      color: #7f8191;
      font-size: 15px;
      font-style: normal;
      font-weight: bold;
      line-height: 24px;
      margin: 0;
    }
  }

  .mainSum {
    color: #2e2e3d;
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    margin-bottom: 10px;
    max-width: 270px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;