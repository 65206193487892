.lable {
  --text-color-black: #2e2e3d;
  --bg-active-color: #f5f7ff;
  --accent-color: #7671dd;

  align-items: center;
  background: #fff;
  border-radius: 1rem;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: fit-content;
  justify-content: space-between;
  min-width: fit-content;
  padding: .4rem .7rem .7rem .75rem;
  position: relative;
  background-image: url("data:image/svg+xml;utf8, <svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' rx='15' style='fill: transparent; stroke: %237671dd; stroke-width: 4; stroke-dasharray: 8 10'/></svg>");


  &_disabled {
    cursor: not-allowed;
  }
}


.contentWrapper {
  align-items: center;
  color: var(--accent-color);
  display: flex;
  justify-content: space-between;
  padding: .7rem .5rem;
  width: 100%;
}


.title {
  display: flex;
  align-items: center;
  color: var(--text-color-black);
  font-size: .9rem;
  font-weight: bold;
  letter-spacing: .08rem;
  margin-bottom: .1rem;

}


.documentIcon {
  margin-right: .9rem;
  color: var(--accent-color);
  min-width: 18px;
}


.plusIcon {
  min-width: 24px;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;