.resultsWrap {
  background: #ffffff;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  border-radius: 15px;
  padding: 30px;
  margin-top: 20px;
}

.resultWrap {
  display: flex;
  margin-bottom: 10px;
  cursor: default;
  p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
  }
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 30px;
    color: #2e2e3d;
    margin: 0 7px 0 0;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;