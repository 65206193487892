.FacultyGradeWrap {
  background: #ffffff;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 88px;
  h3 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #2e2e3d;
    margin: 0;
  }
  
}

.FacultyGradeWrap_active {
  border: 2px solid #7671DD;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;