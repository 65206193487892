.scol {
  .ant-table-wrapper {
    margin-top: 20px;
    tr th {
      background: white;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #7f8191;
      border: 1px solid #e6e9f3;
    }
    tr td {
      border: 1px solid #e6e9f3;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #2e2e3d;
      padding: 16px;
    }
  }
}

.css-67l5gl::before {
  display: none;
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;