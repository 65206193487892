.card {
  --box-shadow: 0px 1.222775936126709px 2.0832479000091553px 0px #7671dd0b,
    0px 3.380819082260132px 5.759913921356201px 0px #7671dd0f,
    0px 8.13970947265625px 13.867653846740723px 0px #7671dd14,
    0px 27px 46px 0px #7671dd1f;

  background-color: #fff;
  padding: 2rem;
  border-radius: 1rem;
  width: calc((100% / 3) - .84rem);
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    box-shadow: var(--box-shadow);
  }
}

.groupName {
  display: flex;
  align-self: center;
  font-weight: 700;
}

.arrow {
  color: #7671DD;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;