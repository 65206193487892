.popup {
  --box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
    0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
    0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
    0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);

  background: #00000099;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 101;

  &_is-open {
    visibility: visible;
  }

  &__outer-container {
    border-radius: 1.3rem;
    /* overflow: hidden; */
    align-self: center;
    width: 45rem;
    max-width: 44rem;
    max-height: 95vh;
    // height: 90vh;
  }

  &__container {
    align-self: center;
    background: #fff;
    color: #2e2e3d;
    height: fit-content;
    padding: 2.5rem;
    position: relative;
    visibility: hidden;
    width: 44rem;
    z-index: 102;
    border-radius: 15px;
    &::-webkit-scrollbar {
      display: none;
    }

    &_with-scroll {
      max-height: 90vh;
      width: 103%;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-right: 3rem;
    }

    &_is-open {
      visibility: visible;
    }
  }

  &__close-button {
    align-items: center;
    background: #fff;
    border-radius: .5rem;
    cursor: pointer;
    display: flex;
    font-size: 1.2rem;
    font-weight: bold;
    height: 3.8rem;
    justify-content: center;
    // position: absolute;
    // right: 0;
    // top: 0;
    transform: translateX(2rem);
    width: 3.8rem;
  }
}





@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;