.card-achievement {
  --approved-color: #7671dd;
  --rejected-color: #ff5e5e;
  --review-color: #f9b754;
  --archived-color: #c5c5c5;
  --text-color-edit: #c5c5c5;
  --text-color-delete: #ff5e5e;
  --text-color-black: #2e2e3d;
  --text-color-grey: #7f8190;
  --border-color-light: #f5f6fa;
  --border-color-not-consider: #F9B754;
  --active-bg-color: #f5f7ff;
  --card-hover-shadow: 0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;

  border-radius: 1rem;
  border: 2px solid var(--border-color-light);
  color: var(--text-color-black);
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  font-weight: bold;
  justify-content: space-between;
  margin: 0 1.2rem 1.3rem 0;
  padding: 1.7rem 1.2rem .7rem 1.2rem;
  width: 26.25rem;

  &_not-consider {
    border-color: var(--border-color-not-consider);
  }

  &__body-wrapper {
    margin-bottom: 1.3rem;
  }

  &__header {
    display: flex;
    height: 1rem;
    justify-content: space-between;
    margin-bottom: .8rem;
  }

  &__status-group {
    display: flex;
  }

  &__icon-status {
    align-items: flex-end;
    display: flex;
    margin-right: .4rem;

    &_approved {
      --approved-color: #7671dd;

      fill: var(--approved-color);
    }

    &_rejected {
      --rejected-color: #ff5e5e;

      fill: var(--rejected-color);
    }

    &_pending {
      --review-color: #f9b754;

      fill: var(--review-color);
    }

    &_archived {
      --archived-color: #c5c5c5;

      fill: var(--archived-color);
    }

    &_appealPending {
      --review-color: #f9b754;

      color: var(--review-color);
    }
  }

  &__text-status {
    align-self: center;
    font-size: 1rem;
    letter-spacing: .03rem;

    &_clickable {
      cursor: pointer;
    }

    &_light {
      font-weight: 400;
    }

    &_approved {
      --approved-color: #7671dd;

      color: var(--approved-color);
    }

    &_is-link {
      cursor: pointer;
    }
  }

  &__description {
    color: var(--text-color-grey);
    font-size: .8rem;
    font-weight: 600;
    letter-spacing: .015rem;
    margin-bottom: .5rem;
  }

  &__title {
    font-size: .9rem;
    font-weight: 700;
    letter-spacing: .07rem;
    margin-bottom: .3rem
  }

  &__subtitle {
    font-size: .8rem;
    font-weight: 600;
    color: var(--text-color-grey);
  }

  &__subtitle-button {
    margin-top: 1.2rem;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__footer-date {
    font-size: 1rem;
    font-weight: 400;
    color: var(--text-color-grey);
    align-self: center;
  }

  &__buttons-wrapper {

    &> :first-child {
      margin-right: .5rem;
    }
  }

  &__button {
    transition: transform .4s ease-in-out;

    &_edit {
      color: var(--text-color-edit);
    }

    &_delete {
      color: var(--text-color-delete);
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }


  &__appeal-wrapper {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: baseline;
  }

  &__appeal-table {
    background: var(--review-color);
    display: flex;
    padding: 0.36rem 0.35rem;
    border-radius: 0.4rem;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    color: #fff;

    &_appealRejected {
      background: var(--rejected-color);
    }

    &_appealApproved {
      background: var(--approved-color);
    }
  }


  &__tag {
    width: fit-content;
    border-radius: .8rem;
    padding: .2rem .6rem;
    font-size: 1rem;
    font-weight: 700;
    color: #fff;

    &_failed {
      background-color: #ff4d4f;
    }

    &_succes {
      background-color: #4caf50;
    }
  }


  &:hover {
    // box-shadow: var(--card-hover-shadow);
    background-color: var(--active-bg-color);
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;