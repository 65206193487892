.ProfilePopup {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  position: absolute;
  top: 56px;
  right: 43px;
  z-index: 4;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 20px 40px;
  margin: 0;
  display: none;
  background: white;
  border-radius: 8px;
}

.popupActive {
  display:block;
}

.popupWrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    z-index: 101;
}

.wrapActive {
  display: block;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;