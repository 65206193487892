.mfcApplicationGeneratorWrap {
  display: grid;
  margin-top: 30px;
  grid-template-columns: repeat(auto-fill, 49%);
  gap: 20px;
  align-items: self-end;
  .calendar-simple .react-calendar {
    width: 40%;
  }
  .calendar-simple__clickable-area {
    padding: 1.2rem;
  }
  .fileName {
    display: flex;
    align-items: center;
    margin-top: 10px;
    svg {
      margin-left: 12px;
      cursor: pointer;
    }
  }
  .dateWrap {
    width: 49%;
    position: relative;
    margin-right: 10px;
    input {
      width: 100%;
    }
    svg {
      position: absolute;
      bottom: 23px;
      right: 15px;
    }
  }
  .multiple {
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    border-radius: 15px;
    //padding: 0px 15px;
    outline: none;
    margin: 20px 10px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    color: #7f8191;
    min-height: 64px;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //padding: 12px 15px;
    span {
      display: flex;
      flex-wrap: wrap;
    }
    input {
      border: none;
      padding: 0px 15px;
      margin: 0;
      min-height: 0;
      outline: none;
      height: 100%;
      border-radius: 10px;
      padding: 15px 15px;
    }
    svg {
      position: initial;
    }
    .specTag {
      display: flex;
      background: #f5f6fa;
      border-radius: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      margin-right: 10px;
      padding: 7px;
      margin-bottom: 5px;
      svg {
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
  .fieldItemWrap {
    border-radius: 10px;
    /* height: fit-content; */
  }
  .filter-drop-down {
    min-width: 0;
  }
}

.formGeneratorListOfFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  .numberSms {
    margin-bottom: 7px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;