.wrapper {
  margin-bottom: 1.2rem;

  &_invisible {
    background-color: #f5f6fa;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.emptyBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #00000099;
}

.documentItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.iconBorder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.125rem;
  width: 3.125rem;
  background-color: #F4F4F4;
  border-radius: .5rem;
  margin-right: 1rem;
}

.fileName {
  font-weight: 700;
}

.trashButton {
  color: #FF5E5E;
}

.attachedFilesList {
  --text-color-dark: #2e2e3d;
  --text-color-light: #c5c5c5;
  --accent-color: #7671dd;
  padding-left: .15rem;
  font-weight: 700;

  &__item {
    display: flex;
    align-items: center;
    color: var(--text-color-dark);
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: .028rem;
    margin-bottom: .5rem;
  }

  &__link,
  &__name {
    color: var(--text-color-dark);
    margin-right: 1.1rem;
  }

  &__name {
    display: flex;
  }

  &__link {

    &:hover {
      color: var(--accent-color)
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;