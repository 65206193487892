.moderatePortfolio {
  .profileTitle {
    padding: 0;
  }
  .ant-table-tbody > tr > td:first-child {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #7671dd;
  }
  .ant-table-wrapper {
    margin-top: 40px;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;