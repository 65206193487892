.dateDescription {
  display: flex;
  gap: 1.25rem;
}

.inputWrapper {
  position: relative;
  width: 300px;
}

.personsWindow {
  max-height: unset !important;
}

.submitButton {
  width: auto;
  align-self: flex-end;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;