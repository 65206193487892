.input-text {
  --texr-color-grey: #7f8190;

  align-items: center;
  background: #fff;
  border: 2px solid #f5f6fa;
  border-radius: 1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  position: relative;
  min-width: fit-content;
  padding: .4rem 1.3rem .7rem .75rem;

  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__label-hint {
    color: var(--texr-color-grey);
    font-size: .9rem;
    letter-spacing: .005rem;
    margin-bottom: .1rem;
  }

  &__value {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .035rem;
    width: 100%;
    line-height: 1.5;
    border: none;
    outline: none;
  }


  &__autosize-text-area {
    border: none;
    resize: none;
    outline: none;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;