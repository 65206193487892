.addStudentItem {
  background: #f5f6fa;
  border-radius: 12px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;

  //transition: all .4s;
  /*  &:hover {
    transform: scale(1.01);
    transition: transform .4s;
  } */
  .nameWrap {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #2e2e3d;
      margin: 0;
    }

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #C5C5C5;
      margin: 0;
    }
  }

  .custom-circleCheck {
    width: 20px;
    height: 20px;
    background-color: #FFFFFF;
    border-radius: 20px;
  }

  .custom-circleCheck_active {
    background-image: url('../../../../../shared/assets/images/Custom_check.png');
  }

  .image {
    width: 48px;
    height: 48px;
    border-radius: 8px;
  }
}

.addStudentItem__checked {
  border: 2px solid #7671DD;
  ;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;