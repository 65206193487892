.projects_introduction {
  .termTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 36px;
    color: #2e2e3d;
    span {
      color: #2e2e3d;
    }
  }
  .ant-table-wrapper tr th {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #7f8191;
  }
  .ant-table-tbody > tr > td {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #2e2e3d;
  }
  .tooltipe {
    min-width: 704px;
    background: #ffffff;
    box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
    border-radius: 15px;
    padding: 20px;
  }
  .ant-table-wrapper {
    overflow: visible;
  } 
  .activeCell {
    z-index: 1001;
    background: white;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: white;
   }
   .css-1hjrgzd-MuiTooltip-tooltip {
    font-size: 30px;
   }
   .arrow {
    color: white;
   }
   .css-1k51tf5-MuiTooltip-tooltip {
    font-size: 30px;
   }
   td {
     position: relative;
   }
   .teacherCell {
     svg {
       display: none;
     }
     &:hover {
      svg {
        display: initial;
      }
     }
   }
   .ant-table-tbody > tr > td  {
    text-align: left;
   }
   #how-get {
    cursor: pointer;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #c5c5c5;
    box-sizing: border-box;
    border-radius: 10px;
    &:checked {
      border: 6px solid #7671dd;
    }
  }
  .studentsWrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .attacheBut {
    border: 2px solid #f0f0f0;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 18px 24px;
    width: fit-content;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all 0.4s;
    margin-right: 22px;
    &:hover {
      transform: scale(1.05);
      transition: all 0.4s;
    }

    svg {
      margin-left: 12px;
    }
    &:last-of-type {
      border: none;
      background: #f5f6fa;
      color: #7f8191;
    }
  }
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;