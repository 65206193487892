.selectNameWrapper {
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;

  &>:first-child {
    flex-grow: 1;
    height: auto;
  }

  &>:last-child {
    height: auto;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;