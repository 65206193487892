.messageIcon {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  .notification {
    position: absolute;
    top: -5px;
    right: -11px;
    width: 16.5px;
    height: 16.5px;
    border: 1px solid #f5f7ff;
    background: #7671dd;
    border-radius: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f5f7ff;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;