.detail-page {
  --color-accent: #7671dd;
  --text-color-black: #2e2e3d;
  --text-color-light: #7f8190;
  --text-color-grey: #7f8191;
  --border-color-grey: #f5f6fa;

  &__history-links-wrapper {
    margin-bottom: 2.5rem;
  }

  &__enroll-students-wrapper {

    & > :not(:last-child) {
      margin-bottom: .5rem;
    }
  }
  .gradeLi {
    &:first-of-type {
      margin-top: 50px;
    }
  }
  .popup__container{
    max-height: 90vh;
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 0;
  }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;