.JoEditPopup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  //background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  z-index: 2;
  .editContainer {
    background: #ffffff;
    box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
    border-radius: 15px;
    padding: 20px;
    width: 704px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px;
    img {
      position: absolute;
      left: 4px;
      top: 35px;
    }
    div {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    h3 {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #7f8191;
    }
    p {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
    }
    label {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      color: #24252e;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      input {
        margin-right: 10px;
      }
    }
    .mark {
      background: #ffffff;
      border: 2px solid #f5f6fa;
      box-sizing: border-box;
      border-radius: 15px;
      width: 100%;
      height: 64px;
      outline: none;
    }
    button {
      background: #7671dd;
      box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
        0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
        0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
        0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
      border-radius: 20px;
      padding: 18px 24px;
      color: white;
      margin-top: 10px;
      align-self: flex-end;
    }
  }
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #2e2e3d;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;