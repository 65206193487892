.popup-content-reject {

  &__title {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: bold;
    letter-spacing: .05rem;
    margin-bottom: 1rem;
  }

  &__text-reason {
    --text-color-accent: #7671dd;

    font-size: 1rem;
    margin-bottom: .2rem;

    &_accent {
      color: var(--text-color-accent);
    }
  }

  &__desription-reason {
    font-size: 1rem;
    letter-spacing: .02rem;
    display: inline-block;
    margin-bottom: 1.2rem;
    word-break: break-all;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;