.label {
  --texr-color-grey: #7f8190;

  align-items: center;
  background: #fff;
  border: 2px solid #f5f6fa;
  border-radius: 1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  position: relative;
  min-width: fit-content;
  padding: .4rem 1.3rem .7rem .75rem;
}


.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}


.title {
  color: var(--texr-color-grey);
  font-size: .9rem;
  letter-spacing: .005rem;
  margin-bottom: .1rem;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;