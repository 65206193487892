.attached-files-list {
  --text-color-dark: #2e2e3d;
  --text-color-light: #c5c5c5;
  --accent-color: #7671dd;
  padding-left: .15rem;

  &__icon {
    color: var(--accent-color);
    height: 1.25rem;
    width: 1.25rem;
    margin-right: .4rem;
  }

  &__item {
    display: flex;
    align-items: center;
    color: var(--text-color-dark);
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: .028rem;
    margin-bottom: .5rem;
  }

  &__link,
  &__name {
    color: var(--text-color-dark);
    margin-right: 1.1rem;
  }

  &__name {
    display: flex;
  }

  &__link {

    &:hover {
      color: var(--accent-color)
    }
  }

  &__file-size {
    font-size: 1rem;
    font-weight: 400;
    color: var(--text-color-light);
    margin-right: .65rem;
  }

  &__remove-file {
    cursor: pointer;
    color: var(--text-color-light);
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;