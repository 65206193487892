
  .filterButtons {
    button {
      background: #ffffff;
      border-radius: 20px;
      margin-right: 10px;
      padding: 18px 24px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #2e2e3d;
      cursor: pointer;
      &:hover {
        outline: 2px solid #7671dd;
      }
    }
    .activeBut {
      outline: none;
      background: #7671dd;
      box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
        0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
        0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
        0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
      color: white;
      &:hover {
        outline: none;
      }
    }
  }

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;