.input {
  min-width: 100%;
  margin-bottom: 10px;
}
.button {
  background-color: #7671DD;
  min-width: 220px;
  min-height: 62px;
  justify-content: space-between;
  color: white;
}

.inputResult {
  margin-bottom: 10px;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;