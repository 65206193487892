.my-education {
  --text-color-black: #2e2e3d;

  .filter-drop-down {
    &:hover {
      outline: 2px solid #c5c5c5;
    }
  }

  .my-education__main-content {
    background: #ffffff;
    box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
    border-radius: 15px;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 66px;
    color: var(--text-color-black);
    margin: 40px 0 20px 0;
  }

  &__navigation-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 1rem;

    & > :first-child {
      margin: 0 0.6rem 0.6rem 0;
    }
  }

  &__buttons-wrapper {
    display: flex;
    flex-wrap: wrap;

    & :not(:last-child) {
      margin: 0 0.55rem 0.55rem 0;
    }
  }
  .my-education__main-content_cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, 26rem);
    grid-gap: 15px 20px;
    gap: 15px 20px;
    margin: 0;
    justify-content: center;
    width: 100%;
  }
  .noContracts {
    width: 100%;
    border: 2px solid #7f8192;
  }
}

.chooseDiscipline {
  cursor: pointer;
}

.noRequiredDisciplines {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #7f8191;
  text-decoration: none;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;