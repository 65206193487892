.library-filters-section {
.library_filteresSearch_wrap {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .open_serach_btn {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: #2e2e3d;
    margin: 0;
    cursor: pointer;
    svg {
      margin-left: 12px;
    }
  }
}
.filters-wrap {
  background: #ffffff;
  box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  padding: 30px;
  height: 400px;
  transition: height 0.4s ease;
  margin-bottom: 20px;
}
.filters-wrap_hidden {
  padding: 0;
  height: 0;
  transition: all 0.4s ease;
  overflow: hidden;
}
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;