.K-WorkerCard {
  background: #ffffff;
  box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
  border-radius: 20px;
  padding: 20px;
  width: 460px;
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #7f8191;
    margin: 0;
  }
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: black;
    margin-bottom: 20px;
  }
  .groupWrap {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 10px 15px;
    background: #f5f6fa;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
      transition: all 0.5s;
    }
    p {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #2e2e3d;
      margin: 0;
    }
    div {
      background: #FFFFFF;
      border-radius: 10px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;