.termCard {
  background: #ffffff;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  border-radius: 15px;
  padding: 30px;
  margin-top: 20px;
  .teachersWrap {
    display: flex;
    flex-wrap: wrap;
    .teacher {
      display: flex;
      width: 50%;
      margin: 20px 0;
      img {
        border-radius: 14px;
        width: 64px;
        height: 64px;
      }
      div {
        display: flex;
        flex-direction: column;
        margin-left: 13px;
      }
    }
    .subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #7f8191;
      margin: 0;
    }
    .value {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #2e2e3d;
      font-style: normal;
      margin: 0 0 10px 0;
    }
  }
  .termRules {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    margin-bottom: 20px;
  }
  #how-get {
    cursor: pointer;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #c5c5c5;
    box-sizing: border-box;
    border-radius: 10px;
    &:checked {
      border: 6px solid #7671dd;
    }
  }
  .ant-table-tbody > tr > td {
    &:nth-of-type(3) {
      padding: 0;
      padding-top: 0;
    }
  }
  .studentsWrap {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    svg {
      margin-left: 7px;
    }
  }
  .editBackground {
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: none;
    z-index: 1000;
  }
  .backVisible {
    display: initial;
  }
  .tooltipe {
    min-width: 704px;
    background: #ffffff;
    box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
    border-radius: 15px;
    padding: 20px;
  }
  .ant-table-wrapper {
    overflow: visible;
  } 
  .activeCell {
    z-index: 1001;
    background: white;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: white;
   }
   .css-1hjrgzd-MuiTooltip-tooltip {
    font-size: 30px;
   }
   .arrow {
    color: white;
   }
   .css-1k51tf5-MuiTooltip-tooltip {
    font-size: 30px;
   }
   td {
     position: relative;
   }
   .teacherCell {
     svg {
       display: none;
     }
     &:hover {
      svg {
        display: initial;
      }
     }
   }
   .ant-table-tbody > tr > td  {
    text-align: left;
   }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;