.chooseField {

  &> {
    text-align: start;
  }

  .fieldSubtitle {

    &_selected {
      color: #2E2E3D !important;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;