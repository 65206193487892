.effective-contract-administrator-template {
  
  &__inputs-inner {
    width: 100%;
  }

  &__inputs-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    & > * {
      margin-bottom: 1rem;
    }
  }
  
  &__filters-inner {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
  }

  &__filters-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 56.8rem;

    & > * {
      margin-bottom: 1rem;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;