.inputItemWrap {
  background: white;
}

.personalLable {
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #a4a6b3;
  margin: 0;
}

.personalInput {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  margin: 0;
  border: none;
  outline: none;
  background: inherit;
  color: #2e2e3d;
}

.inputError {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #ee3465;
  padding-top: 6px;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;