.addTheme {
  background: #ffffff;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  border-radius: 15px;
  padding: 30px;
  margin-top: 20px;
  .filterWrap {
    display: flex;
    margin-top: 20px;
    align-items: center;
    input {
      background: #ffffff;
      border-radius: 15px;
      width: 84%;
      height: 62px;
      border: none;
      outline: none;
      padding-left: 15px;
      border: 2px solid #F5F6FA;
      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 24px;
        color: #7f8191;
      }
    }
  }
  .searchBut {
    background: #7671dd;
    box-shadow: 0px 27px 46px rgba(118, 113, 221, 0.12),
      0px 8.13971px 13.8677px rgba(118, 113, 221, 0.0781907),
      0px 3.38082px 5.75991px rgba(118, 113, 221, 0.06),
      0px 1.22278px 2.08325px rgba(118, 113, 221, 0.0418093);
    border-radius: 15px;
    width: 215px;
    height: 62px;
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    margin-left: 32px;
    cursor: pointer;
    transition: all 0.4s;
    &:hover {
      transform: scale(1.05);
      transition: all 0.4s;
    }
  }
  .disabled {
    background: #7F8191;
    &:hover {
      transform: scale(1);
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;