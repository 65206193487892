.newProjectForm {
  background: #ffffff;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  border-radius: 15px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 36px;
    color: #2e2e3d;
  }
  .type {
    display: flex;
    //justify-content: space-between;
    margin-bottom: 40px;
    flex-wrap: wrap;
    align-items: flex-end;
    input {
      border: 2px solid #f5f6fa;
      box-sizing: border-box;
      border-radius: 15px;
      width: 49%;
      padding: 0px 15px;
      outline: none;
      margin: 20px 10px 0 0;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      color: #7f8191;
      min-height: 64px;
      &:disabled {
        background: none;
      }
    }
    textarea {
      border: 2px solid #f5f6fa;
      box-sizing: border-box;
      border-radius: 15px;
      width: 100%;
      padding: 20px 15px;
      outline: none;
      margin: 20px 0 0 0;
      height: 142px;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      color: #7f8191;
    }
  }
  .statusButton {
    width: fit-content;
    align-self: flex-end;
    transition: all 0.4s;
    &:hover {
      transform: scale(1.05);
      transition: all 0.4s;
    }
    &:disabled {
      background: #7F8191;
    }
  }
  .dateWrap {
    width: 49%;
    position: relative;
    margin-right: 10px;
    input {
      width: 100%;
    }
    svg {
      position: absolute;
      bottom: 23px;
      right: 15px;
    }
  }
  .react-calendar {
    bottom: -304px;
    right: 0;
    z-index: 2;
  }
  .chooseField {
    width: 49%;
    height: fit-content;
    h4 {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      color: #7f8191;
    }
  }
  .multiple {
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    border-radius: 15px;
    //padding: 0px 15px;
    outline: none;
    margin: 20px 10px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    color: #7f8191;
    min-height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //padding: 12px 15px;
    span {
      display: flex;
      flex-wrap: wrap;
    }
    input {
      border: none;
      padding: 0px 15px;
      margin: 0;
      min-height: 0;
    }
    svg {
      position: initial;
    }
    .specTag {
      display: flex;
      background: #f5f6fa;
      border-radius: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #2e2e3d;
      margin-right: 10px;
      padding: 7px;
      margin-bottom: 5px;
      svg {
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
  .projPopup {
    top: 85px;
    max-width: fit-content;
    max-height: 550px;
    overflow-y: scroll;
  }
  .disabled {
    background: #7F8191;
    cursor: default;
    font-size: 16px;
    line-height: 26px;
    padding: 18px 24px;
    border-radius: 20px;
    width: fit-content;
    color: white;
    align-self: flex-end;
  }
  .ant-input-number-input-wrap {
    width: 100%;
    input {
      border: none;
      margin: 0;
      padding: 0;
      width: 100%;
      min-height: 0;
    }
  }

  .ant-input-number {
    height: 64px;
    width: 49%;
  }
  .PhoneInput {
    width: 60%;
  }
  .ant-input-number-input {
    width: 100%;
  }
}

.ant-input-number-borderless {
  border: 2px solid #f5f6fa;
}
.ant-input-number-focused {
  border: 2px solid #f5f6fa;
  box-shadow: none;
}

.ant-input-number:hover {
  border: 2px solid #f5f6fa;
}
.hiddenLayer {
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: none;
}

.hiddenLayer_vis {
  display: block;
}


@media all and (max-width: 1650px) {
  .newProjectForm {
    .type {
      display: flex;
      //justify-content: space-between;
      margin-bottom: 40px;
      flex-wrap: wrap;
      align-items: flex-end;
      input {
        width: 47%;
      }
    }
    .dateWrap {
      input {
        width: 100%;
      }
    }
    .chooseField {
      width: 47%;
    }
  }
}



@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;