.Card {
  width: 460px;
  height: 230px;

  .title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #2E2E3D;
  }

  .subtitle {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 1.0625rem;
    line-height: 1.5rem;
    color: #7F8191;
    
  }
}

.Card .Status {
  margin-left: 5px;

  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  color: white;

  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
}

.Card .Good {
  background-color: #00ba95;
}

.Card .Bad {
    background-color: #eb5757;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;