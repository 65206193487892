.menuButton {
  margin-right: 2.5rem !important;
}

.icon {
  color: #C5C5C5;

  &_dangerColor {
    color: #FF5E5E;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;