.achiveDetails {
  .detailsFilter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
  }

  .chooseField {
    padding: 12px 30px 12px 13px;
    display: flex;
    justify-content: space-between;
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    border-radius: 15px;
    min-height: 64px;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-right: 0;
    background: white;
    margin-top: 10px;

    &:hover {
      outline: 2px solid #c5c5c5;
    }

    h4 {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      color: #7f8191;
      margin: 0;
    }

    .fieldValue {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      color: #2e2e3d;
    }
  }

  .detailCards {
    display: flex;
    flex-wrap: wrap;

    &>.detailsCard,
    &>.card-add-item {
      width: 32.4%;
    }

    &>:nth-child(3n+3) {
      margin: 0 0 1.3rem 0;
      width: 32.52%;
    }

    @media screen and (max-width: 1919px) {

      &>.detailsCard,
      &>.card-add-item {
        width: 32.2%;
      }

      &>:nth-child(3n+3) {
        margin: 0 0 1.3rem 0;
        width: 32.4%;
      }
    }
  }

}

.secondTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #2e2e3d;
  margin: 30px 0 20px 0;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;