.payButtons {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  button {
    transition: all 0.4s;
    border-radius: 20px;
    padding: 25px 28px;
    width: 453px;
    border: 1px solid grey;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    border: 2px solid #F0F0F0;
    box-sizing: border-box;
    filter: drop-shadow(0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015));
    border-radius: 20px;
    display: flex;
    cursor: pointer;
    &:hover {
      transition: all 0.4s;
      background: #7671DD;
      box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
      color: white;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;