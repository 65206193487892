.bubblesWrapper {
  display: flex;
  flex-direction: column;

  &>:not(:last-child) {
    margin-bottom: .5rem;
  }
}

.messageBubbe {
  --primary: #e5e7ee;
  --secondary: #ebeffd;

  display: flex;
  flex-direction: column;
  background-color: var(--primary);
  padding: 1rem 1.5rem;
  width: fit-content;
  border-radius: 1.25rem;

  &_secondary {
    align-self: flex-end;
    background-color: var(--secondary);
  }
}

.authorName {
  font-weight: 700;
  font-size: 1.2rem;

  &_secondary {
    align-self: flex-end;
  }
}

.messageText {
  font-size: 1rem;
  min-width: 350px;
  margin: 0;
  display: flex;
  align-items: center;
}

.scrollContainer {
  max-height: 500px;
  overflow-y: auto;
}

.deleteButton {
  margin-top: 20px;
  border: none;
  border-radius: 4px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  transition: background-color 0.3s ease;
  margin-left: 10px;
}

.deleteIcon {
  margin-right: 8px;
  font-size: 16px;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;