.contactsColumn {
  h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #2e2e3d;
    margin: 0 0 22px 0;
  }
  .contactWrap {
    transition: all 0.4s;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    &:hover{
      color: #7671DD;
    }
    p, a {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 24px;
      color: #2e2e3d;
      margin: 0 0 0 12px;
      &:hover{
        color: #7671DD;
      }
    }
    span {
      transition: all 0.4s;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #2e2e3d;
      margin-bottom: 20px;
      &:hover{
        color: #7671DD;
      }
    }
  }
}

.footerContacts {
  display: flex;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;