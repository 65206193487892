.ratingFilters {
  background: #ffffff;
  box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  padding: 30px;
  margin-top: 20px;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    color: #2e2e3d;
  }
  .MuiAccordionSummary-content{
    display: flex;
    justify-content:space-between;
  }
  .chevronCircle {
    border: 2px solid #f5f6fa;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.4s;
  }

  .chevronUp {
    transform: rotate(-180deg);
    transition: transform 0.4s;
  }

  .dateWrap {
    input {
      border: 2px solid #f5f6fa;
      box-sizing: border-box;
      border-radius: 15px;
      padding: 0px 15px;
      outline: none;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      color: #7f8191;
      width: 100%;
      height: 64px;
      margin-top: 10px;

      &:disabled {
        background: none;
      }
    }
  }

  .multiple {
    padding: 0;
    margin-right: 0;

    input {
      border: none;
      margin: 0;
      max-height: 60px;

    }
  }

  .checkbox {

  }
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  display: none;
}

.filterAccept {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;

  .clear {
    display: flex;
    align-items: center;
    margin: 0 32px 0 0;
    height: 100%;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;