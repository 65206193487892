.inputsWrapper {
  display: flex;
  justify-content: space-between;
  gap: .625rem;
}

.input {
  flex-grow: 1;
  height: auto;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;