.detailsCard {
  background: #ffffff;
  border: 2px solid #f5f6fa;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 22px;
  width: 31%;
  margin: 0 1.2rem 1.3rem 0;

  .statusTilte {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #2e2e3d;
    display: flex;
    align-items: center;

    &_button {
      font-weight: inherit;
      cursor: pointer;
    }

    svg {
      margin-right: 7px;
    }
  }

  .points {
    margin: 10px 0 0 0;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #2e2e3d;
  }

  .achivmentType {
    margin: 10px 0 0 0;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #7f8191;

    span {
      color: #2e2e3d;
    }
  }

  .dateCreate {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #7f8191;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;

    .archiev-button {
      font-size: 1rem;
      font-weight: 700;
      color: #2E2E3D;
      display: flex;

      &_archieved {
        color: #7F8191;
      }

      &:hover {
        cursor: pointer;
      }

      svg {
        margin-left: .5rem;
      }
    }

    svg {
      translate: scale 0.4s;
      cursor: pointer;

      &:hover {
        transform: scale(1.10);
        translate: scale 0.4s;
      }
    }
  }

  .comment {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #7f8191;
    margin: 0;
    margin-left: auto;
    cursor: pointer;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;