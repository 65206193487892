.Rating {
  .profileTitle {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    div {
      cursor: pointer;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      color: #2e2e3d;
      margin: right;
      display: flex;
      align-items: baseline;
      svg {
        margin-left: 12px;
      }
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;