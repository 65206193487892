.popup-content-create {
  
  &__title {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: bold;
    letter-spacing: .05rem;
    margin-bottom: 1rem;
  }

  &__inputs-wrapper {

    & > * {
      margin-bottom: 1rem;
    }
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;