.select {
  outline: none;
  appearance: auto;
  border-radius: .5rem;
  height: 2rem;
}

.select:focus {
  outline: 3px solid black;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;